import { Box } from "@chakra-ui/react";
import React from "react";
import SearchBar from "./SearchBar";

const Navbar = ({ searchTerm, onSearch }) => {
  return (
    <Box
      position={"sticky"}
      top="0"
      zIndex={"100"}
      bg="#f7f8f7"
      width="100%"
      boxShadow=" 0px 7px 7px -5px rgba(170, 159, 170, 0.2)"
    >
      <Box
        minWidth={"30%"}
        width="90%"
        display={{ base: "none", lg: "block" }}
      >
        <SearchBar searchTerm={searchTerm} onSearch={onSearch} />
      </Box>
      <Box padding={"8px"} display={{ lg: "none" }} width="90%" margin="auto">
        <SearchBar searchTerm={searchTerm} onSearch={onSearch} />
      </Box>
    </Box>
  );
};

export default Navbar;
