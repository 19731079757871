import React from "react";
import SearchEngine from "./Core"
import "./App.css";
import { initializeMixpanel } from "./Core/utils/mixpanel";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HerHealth from './Core/components/HerHealth/HerHealth';
import YogaPackagePage from './Core/components/YogaClasses/YogaPackagePage';
import EmotionalSupport from './Core/components/EmotionalSupport/EmotionalSupport';
import HerStore from "./Core/components/HerStore/HerStore";

initializeMixpanel();

function App() {
  return (
    <Router>
    <div className="App">
      <Switch>
        <Route path="/herhealth" exact component={HerHealth} />
        <Route path="/herhealth-yoga" component={YogaPackagePage} />
        <Route path="/herhealth-emotional-support" component={EmotionalSupport} />
        <Route path="/herstore" exact component={HerStore} />
        <Route path="/" component={SearchEngine}/>
      </Switch>
    </div>
  </Router>
  )
}

export default App;
