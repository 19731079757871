import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Image,
  Progress,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import HomeSlider from "./Components/Home/HomeSlider";
import MothersStore from "./Components/Home/MothersStore";
import DhamaalDiscounts from "./Components/Home/DhamaalDiscounts";
import Footer from "./Components/Home/Footer";
import Navbar from "./Components/Home/Navbar";
import {
  seasonsHotDealsImages,
  topBrandsImages,
  dhamaalDiscountsImages,
  mothersStoreImages,
  pregnancyReadImages,
} from "./ListedImages";
import { trackEvent } from "../../utils";

function HomePage() {
  const searchParams = new URLSearchParams(window.location.search);
  let selectedWeek;
  
  if (searchParams.get('week') === null || !searchParams.get('week')) {
    selectedWeek = 4
  } else {
    selectedWeek = searchParams.get('week')
  }

  const selectedSeasonsHotDealsImages = seasonsHotDealsImages.find(
    (item) => item.week === parseInt(selectedWeek, 10)
  );
  const selectedTopBrandsImages = topBrandsImages.find(
    (item) => item.week === parseInt(selectedWeek, 10)
  );
  const selectedPregnancyReadImages = pregnancyReadImages.find(
    (item) => item.week === parseInt(selectedWeek, 10)
  );
  const selectedDhamaalDiscountsImages = dhamaalDiscountsImages.find(
    (item) => item.week === parseInt(selectedWeek, 10)
  );
  const selectedMothersStoreImages = mothersStoreImages.find(
    (item) => item.week === parseInt(selectedWeek, 10)
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredImages, setFilteredImages] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const allImagesSets = [
      selectedSeasonsHotDealsImages,
      selectedTopBrandsImages,
      selectedPregnancyReadImages,
      selectedDhamaalDiscountsImages,
      selectedMothersStoreImages,
    ];

    const filteredImages = allImagesSets.flatMap((imageSet) => {
      if (imageSet) {
        return imageSet.slides.filter(
          (item) =>
            item.keywords.some((keyword) =>
            searchTerm.toLowerCase().includes(keyword.toLowerCase())
            ));
      }
      return [];
    });

    setFilteredImages(filteredImages);

    const timeout = setTimeout(() => {
      trackEvent("store_searched", { searched: searchTerm });
    }, 1000);

    setDebounceTimeout(timeout);
  };

  return (
    <Box bgColor={"#fdfdfd"}>
      <Navbar searchTerm={searchTerm} onSearch={handleSearch} />
      {searchTerm && (
        <>
          <Grid
            templateColumns={{
              base: "repeat(2, 1fr)",
              md: "repeat(4, 1fr)",
            }}
            gap={4}
            marginTop={{ base: 3, sm: 4, md: 6, lg: 7 }}
          >
            {filteredImages &&
              filteredImages.map((slide, i) => (
                <GridItem key={i} borderRadius={"20px"}>
                  {/* Wrap the Image component with an anchor tag */}
                  <a
                    href={slide.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      borderRadius={"20px"}
                      width={"100%"}
                      src={slide.image}
                      alt={`Image ${i + 1}`}
                    />
                  </a>
                </GridItem>
              ))}
          </Grid>
        </>
      )}
      {!searchTerm && (
        <>
          <Box>
            <Progress
              colorScheme="green"
              hasStripe
              height="42px"
              value={100}
              isAnimated
            />
            <Text
              color={"white"}
              fontSize={{ base: "80%", sm: "100%", lg: "100%" }}
              position="absolute"
              zIndex={100}
              top={{ base: "68px", sm: "66px", md: "93px", lg: "76px" }}
              left={{ base: "13%", sm: "27%", md: "30%", lg: "40%" }}
            >
            🤰 Curated pregnancy essentials par best deals! 🤑
            </Text>
          </Box>

          <HomeSlider />

          {/* Our Benifits */}
          <Box
            width={{ base: "90%", sm: "90%", md: "90%", lg: "85%" }}
            margin="auto"
          >
            <Text
              width={{ base: "100px", sm: "150px", md: "150px", lg: "190px" }}
              fontSize={{ base: "100%", sm: "150%", md: "150%", lg: "200%" }}
              fontWeight={"semibold"}
              textAlign="left"
              borderBottom={"2px"}
              borderBottomWidth="5px"
              marginTop={{ base: 10, sm: 3, md: 7, lg: 10 }}
              borderBottomColor={"#f89f17"}
            >
              Our Benefits
            </Text>

            <Flex marginTop={{ base: 3, sm: 4, md: 6, lg: 7 }} gap="20px">
              <Box>
                <Image src="https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Add-a-heading-6.png" />
              </Box>

              <Box>
                <Image src="https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Add-a-heading-7.png" />
              </Box>
            </Flex>
          </Box>

          {/* Dhamaal Discounts */}

          <Box
            width={{ base: "90%", sm: "90%", md: "90%", lg: "85%" }}
            margin="auto"
          >
            <Text
              width={{ base: "180px", sm: "200px", md: "200px", lg: "270px" }}
              fontSize={{ base: "100%", sm: "150%", md: "150%", lg: "200%" }}
              fontWeight={"semibold"}
              textAlign="left"
              borderBottom={"2px"}
              borderBottomWidth="5px"
              marginTop={{ base: 10, sm: 3, md: 7, lg: 10 }}
              borderBottomColor={"#f89f17"}
            >
              Dhamal Discounts 🛍️
            </Text>
            <DhamaalDiscounts images={selectedDhamaalDiscountsImages} />
          </Box>

          {/* Mother's Store */}

          <Box
            width={{ base: "90%", sm: "90%", md: "90%", lg: "85%" }}
            margin="auto"
          >
            <Text
              width={{ base: "180px", sm: "170px", md: "170px", lg: "220px" }}
              fontSize={{ base: "100%", sm: "150%", md: "150%", lg: "200%" }}
              fontWeight={"semibold"}
              textAlign="left"
              borderBottom={"2px"}
              borderBottomWidth="5px"
              marginTop={{ base: 10, sm: 6, md: 7, lg: 10 }}
              borderBottomColor={"#f89f17"}
            >
              🌸 Mother's Store 🌟
            </Text>
            <MothersStore images={selectedMothersStoreImages} />
          </Box>

          {/* Seasons hot deals */}

          <Box
            width={{ base: "90%", sm: "90%", md: "90%", lg: "85%" }}
            margin="auto"
          >
            <Text
              width={{ base: "200px", sm: "250px", md: "250px", lg: "330px" }}
              fontSize={{ base: "100%", sm: "150%", md: "150%", lg: "200%" }}
              fontWeight={"semibold"}
              textAlign="left"
              borderBottom={"2px"}
              borderBottomWidth="5px"
              marginTop={{ base: 10, sm: 6, md: 7, lg: 10 }}
              borderBottomColor={"#f89f17"}
            >
              Season's Hottest Deals 👗
            </Text>

            <Grid
              templateColumns={{
                base: "repeat(2, 1fr)",
                sm: "repeat(3, 1fr)",
                md: "repeat(4, 1fr)",
                lg: "repeat(4, 1fr)",
              }}
              gap={6}
              marginTop={{ base: 3, sm: 4, md: 6, lg: 7 }}
            >
              {selectedSeasonsHotDealsImages &&
                selectedSeasonsHotDealsImages.slides.map((slide, index) => (
                  <a
                    href={slide.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image key={index} src={slide.image} />
                  </a>
                ))}
            </Grid>
          </Box>

          {/* Pregnancy Reads */}

          <Box
            width={{ base: "90%", sm: "90%", md: "90%", lg: "85%" }}
            margin="auto"
          >
            <Text
              width={{ base: "180px", sm: "280px", md: "280px", lg: "370px" }}
              fontSize={{ base: "100%", sm: "150%", md: "150%", lg: "200%" }}
              fontWeight={"semibold"}
              textAlign="left"
              borderBottom={"2px"}
              borderBottomWidth="5px"
              marginTop={{ base: 10, sm: 6, md: 7, lg: 10 }}
              borderBottomColor={"#f89f17"}
            >
              📚 Pregnancy Reads 🍼
            </Text>
            <Grid
              templateColumns={{
                base: "repeat(3, 1fr)",
                sm: "repeat(4, 1fr)",
                md: "repeat(5, 1fr)",
                lg: "repeat(6, 1fr)",
              }}
              gap={4}
              marginTop={{ base: 3, sm: 4, md: 6, lg: 7 }}
            >
              {selectedPregnancyReadImages &&
                selectedPregnancyReadImages.slides.map((slide, index) => (
                  <a
                    href={slide.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image key={index} src={slide.image} />
                  </a>
                ))}
            </Grid>
          </Box>

          {/* Brands We Love */}

          <Box
            width={{ base: "90%", sm: "90%", md: "90%", lg: "85%" }}
            margin="auto"
            mb={"30px"}
          >
            <Text
              width={{ base: "115px", sm: "280px", md: "280px", lg: "370px" }}
              fontSize={{ base: "100%", sm: "150%", md: "150%", lg: "200%" }}
              fontWeight={"semibold"}
              textAlign="left"
              borderBottom={"2px"}
              borderBottomWidth="5px"
              marginTop={{ base: 10, sm: 6, md: 7, lg: 10 }}
              borderBottomColor={"#f89f17"}
            >
              Top Brands 💅
            </Text>
            <Grid
              templateColumns={{
                base: "repeat(3, 1fr)",
                sm: "repeat(4, 1fr)",
                md: "repeat(5, 1fr)",
                lg: "repeat(6, 1fr)",
              }}
              gap={4}
              marginTop={{ base: 3, sm: 4, md: 6, lg: 7 }}
            >
              {selectedTopBrandsImages &&
                selectedTopBrandsImages.slides.map((slide, index) => (
                  <a
                    href={slide.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image key={index} src={slide.image} />
                  </a>
                ))}
            </Grid>
          </Box>

          <Divider />
          <Box
            width={{ base: "90%", sm: "90%", md: "90%", lg: "85%" }}
            margin="auto"
          >
            <Footer />
          </Box>
        </>
      )}
    </Box>
  );
}

export default HomePage;
