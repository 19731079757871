import { Box, Image } from "@chakra-ui/react";
import React from "react";
import Slider from "react-slick";
import { IoIosArrowForward } from "react-icons/io";;

function SampleNextArrow({ onClick }) {
  return (
    <Box
      fontSize={{ base: "20px", sm: "25px", md: "30px", lg: "30px" }}
      padding={{
        base: "2px 2px",
        sm: "3px 5px",
        md: "4px 8px",
        lg: "5px 12px",
      }}
      borderRadius="50%"
      color="black"
      boxShadow="0 0 5px 3px #ccc"
      display="flex"
      width={{ base: "20px", sm: "30px", md: "40px", lg: "50px" }}
      position="absolute"
      top="45%"
      right={"-5px"}
      backgroundColor="white"
      className="arrow arrow-right"
      onClick={onClick}
    >
      <IoIosArrowForward />
    </Box>
  );
}

const DhamaalDiscounts = ({ images }) => {
  let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box marginTop={{ base: 3, sm: 4, md: 6, lg: 7 }}>
      <Slider {...settings}>
        {images &&
          images.slides.map((slide, i) => (
            <Box borderRadius={"20px"} key={i}>
              {/* Wrap the Image component with an anchor tag */}
              <a href={slide.link} target="_blank" rel="noopener noreferrer">
                <Image
                  borderRadius={"20px"}
                  width={"93%"}
                  margin="auto"
                  src={slide.image}
                  alt={`Image ${i + 1}`}
                />
              </a>
            </Box>
          ))}
      </Slider>
    </Box>
  );
};

export default DhamaalDiscounts;
