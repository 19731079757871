import { Box, Image } from "@chakra-ui/react";
import React from "react";
import Slider from "react-slick";
import { mainCarouselImages } from "../../ListedImages";

const HomeSlider = () => {
  const searchParams = new URLSearchParams(window.location.search);
  let selectedWeek;
  
  if (searchParams.get('week') === null || !searchParams.get('week')) {
    selectedWeek = 4
  } else {
    selectedWeek = searchParams.get('week')
  }

  const selectedMainCarouselImages = mainCarouselImages.find(
    (item) => item.week === parseInt(selectedWeek, 10)
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <Box
      width={{ base: "90%", sm: "90%", md: "90%", lg: "85%" }}
      margin="auto"
      marginTop={3}
    >
      <Slider {...settings}>
        {selectedMainCarouselImages &&
          selectedMainCarouselImages.slides.map((slide, i) => (
            <Box borderRadius={"8px"} key={i}>
              <a href={slide.link} target="_blank" rel="noopener noreferrer">
                <Image
                  borderRadius={"8px"}
                  margin={"auto"}
                  width={"100%"}
                  src={slide.image}
                />
              </a>
            </Box>
          ))}
      </Slider>
    </Box>
  );
};

export default HomeSlider;
