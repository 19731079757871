import React from "react";
import HomePage from "./HomePage";
import { ChakraProvider } from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HerStore = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const week = searchParams.get('week')

    return (<ChakraProvider><HomePage /></ChakraProvider>)
}

export default HerStore