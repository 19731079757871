import React from 'react';
import './styles.css';

const YogaPackagePage = () => {
  const paymentLinks = {
    'Single Session':
      'https://pages.razorpay.com/pl_MeTvHc55rwbinY/view',
    Monthly: 'https://pages.razorpay.com/pl_MeU2nhpSwuYBck/view',
    Quarterly: 'https://pages.razorpay.com/pl_MeUhgY9khHJZEt/view',
    'Half Yearly': 'https://pages.razorpay.com/pl_MeWvG1gKoiP3tU/view',
  };

  const handleBuyNow = (plan) => {
    const paymentLink = paymentLinks[plan];

    if (paymentLink) {
      window.open(paymentLink, '_self');
      console.log('Payment link opened successfully!');
    }
  };

  return (
    <div className="container">
      {/* Header Section */}
      <div className="header">
        <h2 className="headerText">🧘‍♀️ Pregnancy Yoga Classes 🧘‍♀️</h2>
        <p className="subHeading">Swasth Bache Ke Liye Yoga Hai Zaroori! 👶</p>
        <br />
        <img
          src={require('../../assets/Images/yoga2.png')}
          alt="Yoga"
          className="headerImage"
        />
      </div>

      {/* Reviews Section */}
      <div className="reviews">
        <span className="stars">★★★★★</span> 5 Reviews
        <br />
        <div className="separator" />

        {/* Yoga Schedule Section */}
        <div className="schedule">
          <span style={{ fontSize: '15px', fontWeight: "bold" }}>
          Timings: 6:00PM to 7:00PM
          </span>
          <div className="separator" />
          <p className="about">
          Your body and your mind are totally engaged in the creation of a new life. Yoga can help you level this up. 
          </p>
          <p className="about">
          Apni pregnancy ko rich aur fulfilling banaiye. Yoga ke anginat faydo ka labh uthaiye! 
          </p>
          <p className="about">Top 3 Benefits of Pregnancy Yoga:</p>
          <p className="about">1. Increased blood flow - your baby gets more oxygenated blood</p>
          <p className="about">2. Aapka labour experience behtar hoga</p>
          <p className="about">3. Aap labour ke liye ho jaaoge bilkul ready!</p>
          <p className="about">Book your slots today, ek strong aur fulfilling pregnancy ko experience kijiye! 🤱</p>
          <div className="separator" />
        </div>

        {/* Package Plans Section */}
        <div className="packages">
          <h3 className="packageHeading">PACKAGE PLANS</h3>
          <div className="separator1" />
          <h3 className="scheduleHeading">
            Select Pregnancy Yoga Classes Package
          </h3>

          <div className="packageRow">
            {/* Card 1 */}
            <div className="packageCard">
              <p>Single Session</p>
              <p className="amount">
                <b>₹ 99</b>
              </p>
              <div className="buyNowButtonContainer">
                <button
                  className="buyNowButton"
                  onClick={() => handleBuyNow('Single Session')}
                >
                  <p className="buyNowButtonText">Book Now</p>
                </button>
              </div>
            </div>

            {/* Card 2 */}
            <div className="packageCard">
              <p>1 Month</p>
              <p className="amount">
                <b>₹ 499</b>
              </p>
              <div className="buyNowButtonContainer">
                <button
                  className="buyNowButton"
                  onClick={() => handleBuyNow('Monthly')}
                >
                  <p className="buyNowButtonText">Book Now</p>
                </button>
              </div>
            </div>
          </div>

          <div className="packageRow">
            {/* Card 3 */}
            <div className="packageCard">
              <p>4 Months</p>
              <p className="amount">
                <b>₹ 1497</b>
              </p>
              <div className="buyNowButtonContainer">
                <button
                  className="buyNowButton"
                  onClick={() => handleBuyNow('Quarterly')}
                >
                  <p className="buyNowButtonText">Book Now</p>
                </button>
              </div>
            </div>

            {/* Card 4 */}
            <div className="packageCard">
              <p>6 Months</p>
              <p className="amount">₹ 2121</p>
              <div className="buyNowButtonContainer">
                <button
                  className="buyNowButton"
                  onClick={() => handleBuyNow('Half Yearly')}
                >
                  <p className="buyNowButtonText">Book Now</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YogaPackagePage;
