export const mainCarouselImages = [
  {
    week: 4,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/HerStore-Carousel-31-5.png",
        link: "https://amzn.to/42PhPXP",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2.png",
        link: "https://amzn.to/3w7rXi9",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2-2.png",
        link: "https://amzn.to/3UziwC8",
      },
    ],
  },
  {
    week: 5,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T1b-Vitals-for-mothers-1.png",
        link: "https://amzn.to/4bxZzpF",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T1b-Wedge-Pregnancy-Pillows-1.png",
        link: "https://amzn.to/42FCIUV",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T1b-Vitals-for-mothers-2-1.png",
        link: "https://amzn.to/42HKgGP",
      },
    ],
  },
  {
    week: 6,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/HerStore-Carousel-31-5.png",
        link: "https://amzn.to/42PhPXP",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2.png",
        link: "https://amzn.to/3w7rXi9",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2-2.png",
        link: "https://amzn.to/3UziwC8",
      },
    ],
  },
  {
    week: 7,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T1b-Vitals-for-mothers-1.png",
        link: "https://amzn.to/4bxZzpF",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T1b-Wedge-Pregnancy-Pillows-1.png",
        link: "https://amzn.to/42FCIUV",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T1b-Vitals-for-mothers-2-1.png",
        link: "https://amzn.to/42HKgGP",
      },
    ],
  },
  {
    week: 8,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/HerStore-Carousel-31-5.png",
        link: "https://amzn.to/42PhPXP",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2.png",
        link: "https://amzn.to/3w7rXi9",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2-2.png",
        link: "https://amzn.to/3UziwC8",
      },
    ],
  },
  {
    week: 9,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T1b-Vitals-for-mothers-1.png",
        link: "https://amzn.to/4bxZzpF",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T1b-Wedge-Pregnancy-Pillows-1.png",
        link: "https://amzn.to/42FCIUV",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T1b-Vitals-for-mothers-2-1.png",
        link: "https://amzn.to/42HKgGP",
      },
    ],
  },
  {
    week: 10,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/HerStore-Carousel-31-5.png",
        link: "https://amzn.to/42PhPXP",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2.png",
        link: "https://amzn.to/3w7rXi9",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2-2.png",
        link: "https://amzn.to/3UziwC8",
      },
    ],
  },
  {
    week: 11,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T1b-Vitals-for-mothers-1.png",
        link: "https://amzn.to/4bxZzpF",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T1b-Wedge-Pregnancy-Pillows-1.png",
        link: "https://amzn.to/42FCIUV",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T1b-Vitals-for-mothers-2-1.png",
        link: "https://amzn.to/42HKgGP",
      },
    ],
  },
  {
    week: 12,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/HerStore-Carousel-31-5.png",
        link: "https://amzn.to/42PhPXP",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2.png",
        link: "https://amzn.to/3w7rXi9",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2-2.png",
        link: "https://amzn.to/3UziwC8",
      },
    ],
  },
  {
    week: 13,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-1.png",
        link: "https://amzn.to/3uD9wRU",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2.png",
        link: "https://amzn.to/3uvbAvu",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2-Herbal-Hair-Care-for-Mothers-2.png",
        link: "https://amzn.to/49tjELR",
      },
    ],
  },
  {
    week: 14,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-1.png",
        link: "https://amzn.to/3uD9wRU",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2.png",
        link: "https://amzn.to/3uvbAvu",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2-Herbal-Hair-Care-for-Mothers-2.png",
        link: "https://amzn.to/49tjELR",
      },
    ],
  },
  {
    week: 15,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-1.png",
        link: "https://amzn.to/3uD9wRU",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2.png",
        link: "https://amzn.to/3uvbAvu",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2-Herbal-Hair-Care-for-Mothers-2.png",
        link: "https://amzn.to/49tjELR",
      },
    ],
  },
  {
    week: 16,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-1.png",
        link: "https://amzn.to/3uD9wRU",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2.png",
        link: "https://amzn.to/3uvbAvu",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2-Herbal-Hair-Care-for-Mothers-2.png",
        link: "https://amzn.to/49tjELR",
      },
    ],
  },
  {
    week: 17,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-1.png",
        link: "https://amzn.to/3uD9wRU",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2.png",
        link: "https://amzn.to/3uvbAvu",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2-Herbal-Hair-Care-for-Mothers-2.png",
        link: "https://amzn.to/49tjELR",
      },
    ],
  },
  {
    week: 18,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-1.png",
        link: "https://amzn.to/3uD9wRU",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2.png",
        link: "https://amzn.to/3uvbAvu",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2-Herbal-Hair-Care-for-Mothers-2.png",
        link: "https://amzn.to/49tjELR",
      },
    ],
  },
  {
    week: 19,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-1.png",
        link: "https://amzn.to/3uD9wRU",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2.png",
        link: "https://amzn.to/3uvbAvu",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2-Herbal-Hair-Care-for-Mothers-2.png",
        link: "https://amzn.to/49tjELR",
      },
    ],
  },
  {
    week: 20,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Digital-Watches-1.png",
        link: "https://amzn.to/3OO6Bg4",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Maternity-Wear-1.png",
        link: "https://amzn.to/48kIlJr",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Pregnancy-Skincare-Kits-1.png",
        link: "https://amzn.to/49mU0J0",
      },
    ],
  },
  {
    week: 21,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Digital-Watches-1.png",
        link: "https://amzn.to/3OO6Bg4",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Maternity-Wear-1.png",
        link: "https://amzn.to/48kIlJr",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Pregnancy-Skincare-Kits-1.png",
        link: "https://amzn.to/49mU0J0",
      },
    ],
  },
  {
    week: 22,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Digital-Watches-1.png",
        link: "https://amzn.to/3OO6Bg4",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Maternity-Wear-1.png",
        link: "https://amzn.to/48kIlJr",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Pregnancy-Skincare-Kits-1.png",
        link: "https://amzn.to/49mU0J0",
      },
    ],
  },
  {
    week: 23,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Digital-Watches-1.png",
        link: "https://amzn.to/3OO6Bg4",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Maternity-Wear-1.png",
        link: "https://amzn.to/48kIlJr",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Pregnancy-Skincare-Kits-1.png",
        link: "https://amzn.to/49mU0J0",
      },
    ],
  },
  {
    week: 24,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Digital-Watches-1.png",
        link: "https://amzn.to/3OO6Bg4",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Maternity-Wear-1.png",
        link: "https://amzn.to/48kIlJr",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Pregnancy-Skincare-Kits-1.png",
        link: "https://amzn.to/49mU0J0",
      },
    ],
  },
  {
    week: 25,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Digital-Watches-1.png",
        link: "https://amzn.to/3OO6Bg4",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Maternity-Wear-1.png",
        link: "https://amzn.to/48kIlJr",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Pregnancy-Skincare-Kits-1.png",
        link: "https://amzn.to/49mU0J0",
      },
    ],
  },
  {
    week: 26,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Digital-Watches-1.png",
        link: "https://amzn.to/3OO6Bg4",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Maternity-Wear-1.png",
        link: "https://amzn.to/48kIlJr",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T2b-Pregnancy-Skincare-Kits-1.png",
        link: "https://amzn.to/49mU0J0",
      },
    ],
  },
  {
    week: 27,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T3a-Pregnancy-Drink-Mix-1.png",
        link: "https://amzn.to/49puqDo",
      },
      {
        keywords: [],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Template-1-2-1.png",
        link: "https://amzn.to/3IbEfJ8",
      },
      {
        keywords: [],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/HerStore-Carousel-31-6-1.png",
        link: "https://amzn.to/49FhsSh",
      },
    ],
  },
  {
    week: 28,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-3-1-1.png",
        link: "https://amzn.to/49tytOV",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-4-1.png",
        link: "https://amzn.to/3SUuzrw",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-5-1.png",
        link: "https://amzn.to/3wzh9cT",
      },
    ],
  },
  {
    week: 29,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T3a-Pregnancy-Drink-Mix-1.png",
        link: "https://amzn.to/49puqDo",
      },
      {
        keywords: [],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Template-1-2-1.png",
        link: "https://amzn.to/3IbEfJ8",
      },
      {
        keywords: [],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/HerStore-Carousel-31-6-1.png",
        link: "https://amzn.to/49FhsSh",
      },
    ],
  },
  {
    week: 30,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-3-1-1.png",
        link: "https://amzn.to/49tytOV",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-4-1.png",
        link: "https://amzn.to/3SUuzrw",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-5-1.png",
        link: "https://amzn.to/3wzh9cT",
      },
    ],
  },
  {
    week: 31,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T3a-Pregnancy-Drink-Mix-1.png",
        link: "https://amzn.to/49puqDo",
      },
      {
        keywords: [],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Template-1-2-1.png",
        link: "https://amzn.to/3IbEfJ8",
      },
      {
        keywords: [],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/HerStore-Carousel-31-6-1.png",
        link: "https://amzn.to/49FhsSh",
      },
    ],
  },
  {
    week: 32,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-3-1-1.png",
        link: "https://amzn.to/49tytOV",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-4-1.png",
        link: "https://amzn.to/3SUuzrw",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-5-1.png",
        link: "https://amzn.to/3wzh9cT",
      },
    ],
  },
  {
    week: 33,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T3a-Pregnancy-Drink-Mix-1.png",
        link: "https://amzn.to/49puqDo",
      },
      {
        keywords: [],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Template-1-2-1.png",
        link: "https://amzn.to/3IbEfJ8",
      },
      {
        keywords: [],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/HerStore-Carousel-31-6-1.png",
        link: "https://amzn.to/49FhsSh",
      },
    ],
  },
  {
    week: 34,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-3-1-1.png",
        link: "https://amzn.to/49tytOV",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-4-1.png",
        link: "https://amzn.to/3SUuzrw",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-5-1.png",
        link: "https://amzn.to/3wzh9cT",
      },
    ],
  },
  {
    week: 35,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T3a-Pregnancy-Drink-Mix-1.png",
        link: "https://amzn.to/49puqDo",
      },
      {
        keywords: [],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Template-1-2-1.png",
        link: "https://amzn.to/3IbEfJ8",
      },
      {
        keywords: [],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/HerStore-Carousel-31-6-1.png",
        link: "https://amzn.to/49FhsSh",
      },
    ],
  },
  {
    week: 36,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-3-1-1.png",
        link: "https://amzn.to/49tytOV",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-4-1.png",
        link: "https://amzn.to/3SUuzrw",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-5-1.png",
        link: "https://amzn.to/3wzh9cT",
      },
    ],
  },
  {
    week: 37,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/T3a-Pregnancy-Drink-Mix-1.png",
        link: "https://amzn.to/49puqDo",
      },
      {
        keywords: [],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Template-1-2-1.png",
        link: "https://amzn.to/3IbEfJ8",
      },
      {
        keywords: [],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/HerStore-Carousel-31-6-1.png",
        link: "https://amzn.to/49FhsSh",
      },
    ],
  },
  {
    week: 38,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-3-1-1.png",
        link: "https://amzn.to/49tytOV",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-4-1.png",
        link: "https://amzn.to/3SUuzrw",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-5-1.png",
        link: "https://amzn.to/3wzh9cT",
      },
    ],
  },
  {
    week: 39,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-3-1-1.png",
        link: "https://amzn.to/49tytOV",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-4-1.png",
        link: "https://amzn.to/3SUuzrw",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-5-1.png",
        link: "https://amzn.to/3wzh9cT",
      },
    ],
  },
  {
    week: 40,
    slides: [
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-3-1-1.png",
        link: "https://amzn.to/49tytOV",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-4-1.png",
        link: "https://amzn.to/3SUuzrw",
      },
      {
        keywords: [],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-1-5-1.png",
        link: "https://amzn.to/3wzh9cT",
      },
    ],
  },
];

export const dhamaalDiscountsImages = [
  {
    week: 4,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2-2-1.png",
        link: "https://amzn.to/3S08A34",
      },
      {
        keywords: [
          "baby",
          "care",
          "bedding",
          "wash",
          "soap",
          "shampoo",
          "himalaya",
          "mumma",
          "earth",
          "cloths",
          "infant",
          "newborn",
          "cotton",
          "set",
          "100%",
          "pure",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2.png",
        link: "https://amzn.to/3Hp7MPa",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1.png",
        link: "https://amzn.to/48yaPQW",
      },
      {
        keywords: [
          "home",
          "decore",
          "cleaning",
          "floor",
          "cleaner",
          "mop",
          "wiper",
          "dettol",
          "liquid",
          "foaming",
          "handwash",
          "handwash",
          "discount",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2-1.png",
        link: "https://amzn.to/3HngCwH",
      },
      {
        keywords: [
          "home",
          "decore",
          "cleaning",
          "floor",
          "cleaner",
          "mop",
          "wiper",
          "dettol",
          "liquid",
          "foaming",
          "handwash",
          "handwash",
          "discount",
          "organizer",
          "essentials",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2-2.png",
        link: "https://amzn.to/3RZV5PL",
      },
    ],
  },
  {
    week: 5,
    slides: [
      {
        keywords: ["dresses", "dress", "gown", "cotton"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-5.png",
        link: "https://amzn.to/42DAwxm",
      },
      {
        keywords: ["gown", "nighty", "night", "suit"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-6-1.png",
        link: "https://amzn.to/3SXOENJ",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "matte",
          "finish",
          "nondrying",
          "non",
          "drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "makeup",
          "puff",
          "puff",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-7-1.png",
        link: "https://amzn.to/3SxKbRs",
      },
      {
        keywords: [
          "clip",
          "pin",
          "hair",
          "bun",
          "juda",
          "clutcher",
          "bobby",
          "pins",
          "hairband",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-8-1.png",
        link: "https://amzn.to/3SFJRA4",
      },
      {
        keywords: [
          "face",
          "oil",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "skin",
          "cleanser",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-9-1.png",
        link: "https://amzn.to/3SF77hx",
      },
    ],
  },
  {
    week: 6,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2-2-1.png",
        link: "https://amzn.to/3S08A34",
      },
      {
        keywords: [
          "baby",
          "care",
          "bedding",
          "wash",
          "soap",
          "shampoo",
          "himalaya",
          "mumma",
          "earth",
          "cloths",
          "infant",
          "newborn",
          "cotton",
          "set",
          "100%",
          "pure",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2.png",
        link: "https://amzn.to/3Hp7MPa",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1.png",
        link: "https://amzn.to/48yaPQW",
      },
      {
        keywords: [
          "home",
          "decore",
          "cleaning",
          "floor",
          "cleaner",
          "mop",
          "wiper",
          "dettol",
          "liquid",
          "foaming",
          "handwash",
          "handwash",
          "discount",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2-1.png",
        link: "https://amzn.to/3HngCwH",
      },
      {
        keywords: [
          "home",
          "decore",
          "cleaning",
          "floor",
          "cleaner",
          "mop",
          "wiper",
          "dettol",
          "liquid",
          "foaming",
          "handwash",
          "handwash",
          "discount",
          "organizer",
          "essentials",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2-2.png",
        link: "https://amzn.to/3RZV5PL",
      },
    ],
  },
  {
    week: 7,
    slides: [
      {
        keywords: ["dresses", "dress", "gown", "cotton"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-5.png",
        link: "https://amzn.to/42DAwxm",
      },
      {
        keywords: ["gown", "nighty", "night", "suit"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-6-1.png",
        link: "https://amzn.to/3SXOENJ",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "matte",
          "finish",
          "nondrying",
          "non",
          "drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "makeup",
          "puff",
          "puff",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-7-1.png",
        link: "https://amzn.to/3SxKbRs",
      },
      {
        keywords: [
          "clip",
          "pin",
          "hair",
          "bun",
          "juda",
          "clutcher",
          "bobby",
          "pins",
          "hairband",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-8-1.png",
        link: "https://amzn.to/3SFJRA4",
      },
      {
        keywords: [
          "face",
          "oil",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "skin",
          "cleanser",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-9-1.png",
        link: "https://amzn.to/3SF77hx",
      },
    ],
  },
  {
    week: 8,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2-2-1.png",
        link: "https://amzn.to/3S08A34",
      },
      {
        keywords: [
          "baby",
          "care",
          "bedding",
          "wash",
          "soap",
          "shampoo",
          "himalaya",
          "mumma",
          "earth",
          "cloths",
          "infant",
          "newborn",
          "cotton",
          "set",
          "100%",
          "pure",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2.png",
        link: "https://amzn.to/3Hp7MPa",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1.png",
        link: "https://amzn.to/48yaPQW",
      },
      {
        keywords: [
          "home",
          "decore",
          "cleaning",
          "floor",
          "cleaner",
          "mop",
          "wiper",
          "dettol",
          "liquid",
          "foaming",
          "handwash",
          "handwash",
          "discount",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2-1.png",
        link: "https://amzn.to/3HngCwH",
      },
      {
        keywords: [
          "home",
          "decore",
          "cleaning",
          "floor",
          "cleaner",
          "mop",
          "wiper",
          "dettol",
          "liquid",
          "foaming",
          "handwash",
          "handwash",
          "discount",
          "organizer",
          "essentials",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2-2.png",
        link: "https://amzn.to/3RZV5PL",
      },
    ],
  },
  {
    week: 9,
    slides: [
      {
        keywords: ["dresses", "dress", "gown", "cotton"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-5.png",
        link: "https://amzn.to/42DAwxm",
      },
      {
        keywords: ["gown", "nighty", "night", "suit"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-6-1.png",
        link: "https://amzn.to/3SXOENJ",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "matte",
          "finish",
          "nondrying",
          "non",
          "drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "makeup",
          "puff",
          "puff",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-7-1.png",
        link: "https://amzn.to/3SxKbRs",
      },
      {
        keywords: [
          "clip",
          "pin",
          "hair",
          "bun",
          "juda",
          "clutcher",
          "bobby",
          "pins",
          "hairband",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-8-1.png",
        link: "https://amzn.to/3SFJRA4",
      },
      {
        keywords: [
          "face",
          "oil",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "skin",
          "cleanser",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-9-1.png",
        link: "https://amzn.to/3SF77hx",
      },
    ],
  },
  {
    week: 10,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2-2-1.png",
        link: "https://amzn.to/3S08A34",
      },
      {
        keywords: [
          "baby",
          "care",
          "bedding",
          "wash",
          "soap",
          "shampoo",
          "himalaya",
          "mumma",
          "earth",
          "cloths",
          "infant",
          "newborn",
          "cotton",
          "set",
          "100%",
          "pure",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2.png",
        link: "https://amzn.to/3Hp7MPa",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1.png",
        link: "https://amzn.to/48yaPQW",
      },
      {
        keywords: [
          "home",
          "decore",
          "cleaning",
          "floor",
          "cleaner",
          "mop",
          "wiper",
          "dettol",
          "liquid",
          "foaming",
          "handwash",
          "handwash",
          "discount",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2-1.png",
        link: "https://amzn.to/3HngCwH",
      },
      {
        keywords: [
          "home",
          "decore",
          "cleaning",
          "floor",
          "cleaner",
          "mop",
          "wiper",
          "dettol",
          "liquid",
          "foaming",
          "handwash",
          "handwash",
          "discount",
          "organizer",
          "essentials",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2-2.png",
        link: "https://amzn.to/3RZV5PL",
      },
    ],
  },
  {
    week: 11,
    slides: [
      {
        keywords: ["dresses", "dress", "gown", "cotton"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-5.png",
        link: "https://amzn.to/42DAwxm",
      },
      {
        keywords: ["gown", "nighty", "night", "suit"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-6-1.png",
        link: "https://amzn.to/3SXOENJ",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "matte",
          "finish",
          "nondrying",
          "non",
          "drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "makeup",
          "puff",
          "puff",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-7-1.png",
        link: "https://amzn.to/3SxKbRs",
      },
      {
        keywords: [
          "clip",
          "pin",
          "hair",
          "bun",
          "juda",
          "clutcher",
          "bobby",
          "pins",
          "hairband",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-8-1.png",
        link: "https://amzn.to/3SFJRA4",
      },
      {
        keywords: [
          "face",
          "oil",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "skin",
          "cleanser",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-9-1.png",
        link: "https://amzn.to/3SF77hx",
      },
    ],
  },
  {
    week: 12,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-2-2-1.png",
        link: "https://amzn.to/3S08A34",
      },
      {
        keywords: [
          "baby",
          "care",
          "bedding",
          "wash",
          "soap",
          "shampoo",
          "himalaya",
          "mumma",
          "earth",
          "cloths",
          "infant",
          "newborn",
          "cotton",
          "set",
          "100%",
          "pure",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2.png",
        link: "https://amzn.to/3Hp7MPa",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1.png",
        link: "https://amzn.to/48yaPQW",
      },
      {
        keywords: [
          "home",
          "decore",
          "cleaning",
          "floor",
          "cleaner",
          "mop",
          "wiper",
          "dettol",
          "liquid",
          "foaming",
          "handwash",
          "handwash",
          "discount",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2-1.png",
        link: "https://amzn.to/3HngCwH",
      },
      {
        keywords: [
          "home",
          "decore",
          "cleaning",
          "floor",
          "cleaner",
          "mop",
          "wiper",
          "dettol",
          "liquid",
          "foaming",
          "handwash",
          "handwash",
          "discount",
          "organizer",
          "essentials",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Dhamaal-Discounts-2-2.png",
        link: "https://amzn.to/3RZV5PL",
      },
    ],
  },
  {
    week: 13,
    slides: [
      {
        keywords: [
          "pregnancy",
          "pants",
          "seamless",
          "legging",
          "leggings",
          "night",
          "dress",
          "nighty",
          "gown",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-1.png",
        link: "https://amzn.to/4bAZ3Y1",
      },
      {
        keywords: [
          "shatavari",
          "satavari",
          "health",
          "healthy",
          "dabur",
          "chyawanprash",
          "immunity",
          "ayurveda",
          "heart",
          "cholestrol",
          "care",
          "diabetic",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-2-1.png",
        link: "https://amzn.to/3uDofwq",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "lips",
          "lipstrick",
          "compact",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-3-1.png",
        link: "https://amzn.to/48fohIt",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-4-1-1-1.png",
        link: "https://amzn.to/3IkQiUJ",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-6-1-1-1.png",
        link: "https://amzn.to/3OLsay5",
      },
    ],
  },
  {
    week: 14,
    slides: [
      {
        keywords: [
          "pregnancy",
          "pants",
          "seamless",
          "legging",
          "leggings",
          "night",
          "dress",
          "nighty",
          "gown",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-1.png",
        link: "https://amzn.to/4bAZ3Y1",
      },
      {
        keywords: [
          "shatavari",
          "satavari",
          "health",
          "healthy",
          "dabur",
          "chyawanprash",
          "immunity",
          "ayurveda",
          "heart",
          "cholestrol",
          "care",
          "diabetic",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-2-1.png",
        link: "https://amzn.to/3uDofwq",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "lips",
          "lipstrick",
          "compact",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-3-1.png",
        link: "https://amzn.to/48fohIt",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-4-1-1-1.png",
        link: "https://amzn.to/3IkQiUJ",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-6-1-1-1.png",
        link: "https://amzn.to/3OLsay5",
      },
    ],
  },
  {
    week: 15,
    slides: [
      {
        keywords: [
          "pregnancy",
          "pants",
          "seamless",
          "legging",
          "leggings",
          "night",
          "dress",
          "nighty",
          "gown",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-1.png",
        link: "https://amzn.to/4bAZ3Y1",
      },
      {
        keywords: [
          "shatavari",
          "satavari",
          "health",
          "healthy",
          "dabur",
          "chyawanprash",
          "immunity",
          "ayurveda",
          "heart",
          "cholestrol",
          "care",
          "diabetic",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-2-1.png",
        link: "https://amzn.to/3uDofwq",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "lips",
          "lipstrick",
          "compact",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-3-1.png",
        link: "https://amzn.to/48fohIt",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-4-1-1-1.png",
        link: "https://amzn.to/3IkQiUJ",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-6-1-1-1.png",
        link: "https://amzn.to/3OLsay5",
      },
    ],
  },
  {
    week: 16,
    slides: [
      {
        keywords: [
          "pregnancy",
          "pants",
          "seamless",
          "legging",
          "leggings",
          "night",
          "dress",
          "nighty",
          "gown",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-1.png",
        link: "https://amzn.to/4bAZ3Y1",
      },
      {
        keywords: [
          "shatavari",
          "satavari",
          "health",
          "healthy",
          "dabur",
          "chyawanprash",
          "immunity",
          "ayurveda",
          "heart",
          "cholestrol",
          "care",
          "diabetic",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-2-1.png",
        link: "https://amzn.to/3uDofwq",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "lips",
          "lipstrick",
          "compact",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-3-1.png",
        link: "https://amzn.to/48fohIt",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-4-1-1-1.png",
        link: "https://amzn.to/3IkQiUJ",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-6-1-1-1.png",
        link: "https://amzn.to/3OLsay5",
      },
    ],
  },
  {
    week: 17,
    slides: [
      {
        keywords: [
          "pregnancy",
          "pants",
          "seamless",
          "legging",
          "leggings",
          "night",
          "dress",
          "nighty",
          "gown",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-1.png",
        link: "https://amzn.to/4bAZ3Y1",
      },
      {
        keywords: [
          "shatavari",
          "satavari",
          "health",
          "healthy",
          "dabur",
          "chyawanprash",
          "immunity",
          "ayurveda",
          "heart",
          "cholestrol",
          "care",
          "diabetic",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-2-1.png",
        link: "https://amzn.to/3uDofwq",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "lips",
          "lipstrick",
          "compact",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-3-1.png",
        link: "https://amzn.to/48fohIt",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-4-1-1-1.png",
        link: "https://amzn.to/3IkQiUJ",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-6-1-1-1.png",
        link: "https://amzn.to/3OLsay5",
      },
    ],
  },
  {
    week: 18,
    slides: [
      {
        keywords: [
          "pregnancy",
          "pants",
          "seamless",
          "legging",
          "leggings",
          "night",
          "dress",
          "nighty",
          "gown",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-1.png",
        link: "https://amzn.to/4bAZ3Y1",
      },
      {
        keywords: [
          "shatavari",
          "satavari",
          "health",
          "healthy",
          "dabur",
          "chyawanprash",
          "immunity",
          "ayurveda",
          "heart",
          "cholestrol",
          "care",
          "diabetic",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-2-1.png",
        link: "https://amzn.to/3uDofwq",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "lips",
          "lipstrick",
          "compact",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-3-1.png",
        link: "https://amzn.to/48fohIt",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-4-1-1-1.png",
        link: "https://amzn.to/3IkQiUJ",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-6-1-1-1.png",
        link: "https://amzn.to/3OLsay5",
      },
    ],
  },
  {
    week: 19,
    slides: [
      {
        keywords: [
          "pregnancy",
          "pants",
          "seamless",
          "legging",
          "leggings",
          "night",
          "dress",
          "nighty",
          "gown",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-1.png",
        link: "https://amzn.to/4bAZ3Y1",
      },
      {
        keywords: [
          "shatavari",
          "satavari",
          "health",
          "healthy",
          "dabur",
          "chyawanprash",
          "immunity",
          "ayurveda",
          "heart",
          "cholestrol",
          "care",
          "diabetic",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-2-1.png",
        link: "https://amzn.to/3uDofwq",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "lips",
          "lipstrick",
          "compact",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-3-1.png",
        link: "https://amzn.to/48fohIt",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-4-1-1-1.png",
        link: "https://amzn.to/3IkQiUJ",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-6-1-1-1.png",
        link: "https://amzn.to/3OLsay5",
      },
    ],
  },
  {
    week: 20,
    slides: [
      {
        keywords: [
          "women",
          "casual",
          "shoes",
          "sandals",
          "loafers",
          "doctor",
          "slipper",
          "sneakers",
          "canvas",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-7-1.png",
        link: "https://amzn.to/3I7z8cT",
      },
      {
        keywords: [
          "handbags",
          "purses",
          "clutches",
          "women's",
          "totes",
          "shoulder",
          "crossbody",
          "satchels",
          "hobo",
          "evening",
          "bags",
          "bag",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-8-1.png",
        link: "https://amzn.to/49GXTbB",
      },
      {
        keywords: [
          "fragrance",
          "perfume",
          "scent",
          "cologne",
          "parfum",
          "eau",
          "de",
          "toilette",
          "aftershave",
          "body",
          "spray",
          "perfume oil",
          "attar",
          "mist",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-9-1.png",
        link: "https://amzn.to/3OSP7PP",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-10-1.png",
        link: "https://amzn.to/3UQ1hwC",
      },
      {
        keywords: [
          "artwork",
          "craft",
          "materials",
          "furniture",
          "heating",
          "cooling",
          "air",
          "quality",
          "home",
          "décor",
          "furnishing",
          "improvement",
          "storage",
          "organisation",
          "indoor",
          "lighting",
          "kitchen",
          "dining",
          "appliances",
          "large",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-11-1.png",
        link: "https://amzn.to/3uD6H3v",
      },
    ],
  },
  {
    week: 21,
    slides: [
      {
        keywords: [
          "women",
          "casual",
          "shoes",
          "sandals",
          "loafers",
          "doctor",
          "slipper",
          "sneakers",
          "canvas",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-7-1.png",
        link: "https://amzn.to/3I7z8cT",
      },
      {
        keywords: [
          "handbags",
          "purses",
          "clutches",
          "women's",
          "totes",
          "shoulder",
          "crossbody",
          "satchels",
          "hobo",
          "evening",
          "bags",
          "bag",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-8-1.png",
        link: "https://amzn.to/49GXTbB",
      },
      {
        keywords: [
          "fragrance",
          "perfume",
          "scent",
          "cologne",
          "parfum",
          "eau",
          "de",
          "toilette",
          "aftershave",
          "body",
          "spray",
          "perfume oil",
          "attar",
          "mist",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-9-1.png",
        link: "https://amzn.to/3OSP7PP",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-10-1.png",
        link: "https://amzn.to/3UQ1hwC",
      },
      {
        keywords: [
          "artwork",
          "craft",
          "materials",
          "furniture",
          "heating",
          "cooling",
          "air",
          "quality",
          "home",
          "décor",
          "furnishing",
          "improvement",
          "storage",
          "organisation",
          "indoor",
          "lighting",
          "kitchen",
          "dining",
          "appliances",
          "large",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-11-1.png",
        link: "https://amzn.to/3uD6H3v",
      },
    ],
  },
  {
    week: 22,
    slides: [
      {
        keywords: [
          "women",
          "casual",
          "shoes",
          "sandals",
          "loafers",
          "doctor",
          "slipper",
          "sneakers",
          "canvas",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-7-1.png",
        link: "https://amzn.to/3I7z8cT",
      },
      {
        keywords: [
          "handbags",
          "purses",
          "clutches",
          "women's",
          "totes",
          "shoulder",
          "crossbody",
          "satchels",
          "hobo",
          "evening",
          "bags",
          "bag",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-8-1.png",
        link: "https://amzn.to/49GXTbB",
      },
      {
        keywords: [
          "fragrance",
          "perfume",
          "scent",
          "cologne",
          "parfum",
          "eau",
          "de",
          "toilette",
          "aftershave",
          "body",
          "spray",
          "perfume oil",
          "attar",
          "mist",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-9-1.png",
        link: "https://amzn.to/3OSP7PP",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-10-1.png",
        link: "https://amzn.to/3UQ1hwC",
      },
      {
        keywords: [
          "artwork",
          "craft",
          "materials",
          "furniture",
          "heating",
          "cooling",
          "air",
          "quality",
          "home",
          "décor",
          "furnishing",
          "improvement",
          "storage",
          "organisation",
          "indoor",
          "lighting",
          "kitchen",
          "dining",
          "appliances",
          "large",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-11-1.png",
        link: "https://amzn.to/3uD6H3v",
      },
    ],
  },
  {
    week: 23,
    slides: [
      {
        keywords: [
          "women",
          "casual",
          "shoes",
          "sandals",
          "loafers",
          "doctor",
          "slipper",
          "sneakers",
          "canvas",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-7-1.png",
        link: "https://amzn.to/3I7z8cT",
      },
      {
        keywords: [
          "handbags",
          "purses",
          "clutches",
          "women's",
          "totes",
          "shoulder",
          "crossbody",
          "satchels",
          "hobo",
          "evening",
          "bags",
          "bag",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-8-1.png",
        link: "https://amzn.to/49GXTbB",
      },
      {
        keywords: [
          "fragrance",
          "perfume",
          "scent",
          "cologne",
          "parfum",
          "eau",
          "de",
          "toilette",
          "aftershave",
          "body",
          "spray",
          "perfume oil",
          "attar",
          "mist",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-9-1.png",
        link: "https://amzn.to/3OSP7PP",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-10-1.png",
        link: "https://amzn.to/3UQ1hwC",
      },
      {
        keywords: [
          "artwork",
          "craft",
          "materials",
          "furniture",
          "heating",
          "cooling",
          "air",
          "quality",
          "home",
          "décor",
          "furnishing",
          "improvement",
          "storage",
          "organisation",
          "indoor",
          "lighting",
          "kitchen",
          "dining",
          "appliances",
          "large",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-11-1.png",
        link: "https://amzn.to/3uD6H3v",
      },
    ],
  },
  {
    week: 24,
    slides: [
      {
        keywords: [
          "women",
          "casual",
          "shoes",
          "sandals",
          "loafers",
          "doctor",
          "slipper",
          "sneakers",
          "canvas",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-7-1.png",
        link: "https://amzn.to/3I7z8cT",
      },
      {
        keywords: [
          "handbags",
          "purses",
          "clutches",
          "women's",
          "totes",
          "shoulder",
          "crossbody",
          "satchels",
          "hobo",
          "evening",
          "bags",
          "bag",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-8-1.png",
        link: "https://amzn.to/49GXTbB",
      },
      {
        keywords: [
          "fragrance",
          "perfume",
          "scent",
          "cologne",
          "parfum",
          "eau",
          "de",
          "toilette",
          "aftershave",
          "body",
          "spray",
          "perfume oil",
          "attar",
          "mist",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-9-1.png",
        link: "https://amzn.to/3OSP7PP",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-10-1.png",
        link: "https://amzn.to/3UQ1hwC",
      },
      {
        keywords: [
          "artwork",
          "craft",
          "materials",
          "furniture",
          "heating",
          "cooling",
          "air",
          "quality",
          "home",
          "décor",
          "furnishing",
          "improvement",
          "storage",
          "organisation",
          "indoor",
          "lighting",
          "kitchen",
          "dining",
          "appliances",
          "large",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-11-1.png",
        link: "https://amzn.to/3uD6H3v",
      },
    ],
  },
  {
    week: 25,
    slides: [
      {
        keywords: [
          "women",
          "casual",
          "shoes",
          "sandals",
          "loafers",
          "doctor",
          "slipper",
          "sneakers",
          "canvas",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-7-1.png",
        link: "https://amzn.to/3I7z8cT",
      },
      {
        keywords: [
          "handbags",
          "purses",
          "clutches",
          "women's",
          "totes",
          "shoulder",
          "crossbody",
          "satchels",
          "hobo",
          "evening",
          "bags",
          "bag",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-8-1.png",
        link: "https://amzn.to/49GXTbB",
      },
      {
        keywords: [
          "fragrance",
          "perfume",
          "scent",
          "cologne",
          "parfum",
          "eau",
          "de",
          "toilette",
          "aftershave",
          "body",
          "spray",
          "perfume oil",
          "attar",
          "mist",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-9-1.png",
        link: "https://amzn.to/3OSP7PP",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-10-1.png",
        link: "https://amzn.to/3UQ1hwC",
      },
      {
        keywords: [
          "artwork",
          "craft",
          "materials",
          "furniture",
          "heating",
          "cooling",
          "air",
          "quality",
          "home",
          "décor",
          "furnishing",
          "improvement",
          "storage",
          "organisation",
          "indoor",
          "lighting",
          "kitchen",
          "dining",
          "appliances",
          "large",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-11-1.png",
        link: "https://amzn.to/3uD6H3v",
      },
    ],
  },
  {
    week: 26,
    slides: [
      {
        keywords: [
          "women",
          "casual",
          "shoes",
          "sandals",
          "loafers",
          "doctor",
          "slipper",
          "sneakers",
          "canvas",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-7-1.png",
        link: "https://amzn.to/3I7z8cT",
      },
      {
        keywords: [
          "handbags",
          "purses",
          "clutches",
          "women's",
          "totes",
          "shoulder",
          "crossbody",
          "satchels",
          "hobo",
          "evening",
          "bags",
          "bag",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-8-1.png",
        link: "https://amzn.to/49GXTbB",
      },
      {
        keywords: [
          "fragrance",
          "perfume",
          "scent",
          "cologne",
          "parfum",
          "eau",
          "de",
          "toilette",
          "aftershave",
          "body",
          "spray",
          "perfume oil",
          "attar",
          "mist",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-9-1.png",
        link: "https://amzn.to/3OSP7PP",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-10-1.png",
        link: "https://amzn.to/3UQ1hwC",
      },
      {
        keywords: [
          "artwork",
          "craft",
          "materials",
          "furniture",
          "heating",
          "cooling",
          "air",
          "quality",
          "home",
          "décor",
          "furnishing",
          "improvement",
          "storage",
          "organisation",
          "indoor",
          "lighting",
          "kitchen",
          "dining",
          "appliances",
          "large",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-11-1.png",
        link: "https://amzn.to/3uD6H3v",
      },
    ],
  },
  {
    week: 27,
    slides: [
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-11-1.png",
        link: "https://amzn.to/49OOChX",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
          "bags",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-12-1.png",
        link: "https://amzn.to/42NwmTK",
      },
      {
        keywords: ["bottle", "water", "brass", "container", "ghada"],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-13-1.png",
        link: "https://amzn.to/4bJEzMF",
      },
      {
        keywords: ["baby", "shower", "god", "bharai", "godbharai"],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-15-1.png",
        link: "https://amzn.to/3wvIsVC",
      },
      {
        keywords: [
          "breastfeeding",
          "accessories",
          "nursing",
          "breast",
          "pads",
          "bras",
          "pillows",
          "breast",
          "pumps",
          "nipple",
          "shields",
          "milk",
          "storage",
          "bags",
          "covers",
          "creams",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-16-1.png",
        link: "https://amzn.to/3uQeo6j",
      },
    ],
  },
  {
    week: 28,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-12-1.png",
        link: "https://amzn.to/4c5DCib",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "tops",
          "panties",
          "bra",
          "pants",
          "leggings",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-13-min.png",
        link: "https://amzn.to/49PRvPu",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
          "bags",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-13-1.png",
        link: "https://amzn.to/3uN5QNE",
      },
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
          "kits",
          "facial",
          "rash",
          "cream",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-14-1.png",
        link: "https://amzn.to/3TeQBGw",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
          "belly",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-15.png",
        link: "https://amzn.to/3uEtzjb",
      },
    ],
  },
  {
    week: 29,
    slides: [
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-11-1.png",
        link: "https://amzn.to/49OOChX",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
          "bags",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-12-1.png",
        link: "https://amzn.to/42NwmTK",
      },
      {
        keywords: ["bottle", "water", "brass", "container", "ghada"],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-13-1.png",
        link: "https://amzn.to/4bJEzMF",
      },
      {
        keywords: ["baby", "shower", "god", "bharai", "godbharai"],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-15-1.png",
        link: "https://amzn.to/3wvIsVC",
      },
      {
        keywords: [
          "breastfeeding",
          "accessories",
          "nursing",
          "breast",
          "pads",
          "bras",
          "pillows",
          "breast",
          "pumps",
          "nipple",
          "shields",
          "milk",
          "storage",
          "bags",
          "covers",
          "creams",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-16-1.png",
        link: "https://amzn.to/3uQeo6j",
      },
    ],
  },
  {
    week: 30,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-12-1.png",
        link: "https://amzn.to/4c5DCib",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "tops",
          "panties",
          "bra",
          "pants",
          "leggings",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-13-min.png",
        link: "https://amzn.to/49PRvPu",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
          "bags",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-13-1.png",
        link: "https://amzn.to/3uN5QNE",
      },
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
          "kits",
          "facial",
          "rash",
          "cream",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-14-1.png",
        link: "https://amzn.to/3TeQBGw",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
          "belly",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-15.png",
        link: "https://amzn.to/3uEtzjb",
      },
    ],
  },
  {
    week: 31,
    slides: [
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-11-1.png",
        link: "https://amzn.to/49OOChX",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
          "bags",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-12-1.png",
        link: "https://amzn.to/42NwmTK",
      },
      {
        keywords: ["bottle", "water", "brass", "container", "ghada"],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-13-1.png",
        link: "https://amzn.to/4bJEzMF",
      },
      {
        keywords: ["baby", "shower", "god", "bharai", "godbharai"],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-15-1.png",
        link: "https://amzn.to/3wvIsVC",
      },
      {
        keywords: [
          "breastfeeding",
          "accessories",
          "nursing",
          "breast",
          "pads",
          "bras",
          "pillows",
          "breast",
          "pumps",
          "nipple",
          "shields",
          "milk",
          "storage",
          "bags",
          "covers",
          "creams",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-16-1.png",
        link: "https://amzn.to/3uQeo6j",
      },
    ],
  },
  {
    week: 32,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-12-1.png",
        link: "https://amzn.to/4c5DCib",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "tops",
          "panties",
          "bra",
          "pants",
          "leggings",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-13-min.png",
        link: "https://amzn.to/49PRvPu",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
          "bags",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-13-1.png",
        link: "https://amzn.to/3uN5QNE",
      },
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
          "kits",
          "facial",
          "rash",
          "cream",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-14-1.png",
        link: "https://amzn.to/3TeQBGw",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
          "belly",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-15.png",
        link: "https://amzn.to/3uEtzjb",
      },
    ],
  },
  {
    week: 33,
    slides: [
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-11-1.png",
        link: "https://amzn.to/49OOChX",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
          "bags",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-12-1.png",
        link: "https://amzn.to/42NwmTK",
      },
      {
        keywords: ["bottle", "water", "brass", "container", "ghada"],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-13-1.png",
        link: "https://amzn.to/4bJEzMF",
      },
      {
        keywords: ["baby", "shower", "god", "bharai", "godbharai"],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-15-1.png",
        link: "https://amzn.to/3wvIsVC",
      },
      {
        keywords: [
          "breastfeeding",
          "accessories",
          "nursing",
          "breast",
          "pads",
          "bras",
          "pillows",
          "breast",
          "pumps",
          "nipple",
          "shields",
          "milk",
          "storage",
          "bags",
          "covers",
          "creams",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-16-1.png",
        link: "https://amzn.to/3uQeo6j",
      },
    ],
  },
  {
    week: 34,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-12-1.png",
        link: "https://amzn.to/4c5DCib",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "tops",
          "panties",
          "bra",
          "pants",
          "leggings",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-13-min.png",
        link: "https://amzn.to/49PRvPu",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
          "bags",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-13-1.png",
        link: "https://amzn.to/3uN5QNE",
      },
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
          "kits",
          "facial",
          "rash",
          "cream",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-14-1.png",
        link: "https://amzn.to/3TeQBGw",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
          "belly",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-15.png",
        link: "https://amzn.to/3uEtzjb",
      },
    ],
  },
  {
    week: 35,
    slides: [
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-11-1.png",
        link: "https://amzn.to/49OOChX",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
          "bags",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-12-1.png",
        link: "https://amzn.to/42NwmTK",
      },
      {
        keywords: ["bottle", "water", "brass", "container", "ghada"],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-13-1.png",
        link: "https://amzn.to/4bJEzMF",
      },
      {
        keywords: ["baby", "shower", "god", "bharai", "godbharai"],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-15-1.png",
        link: "https://amzn.to/3wvIsVC",
      },
      {
        keywords: [
          "breastfeeding",
          "accessories",
          "nursing",
          "breast",
          "pads",
          "bras",
          "pillows",
          "breast",
          "pumps",
          "nipple",
          "shields",
          "milk",
          "storage",
          "bags",
          "covers",
          "creams",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-16-1.png",
        link: "https://amzn.to/3uQeo6j",
      },
    ],
  },
  {
    week: 36,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-12-1.png",
        link: "https://amzn.to/4c5DCib",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "tops",
          "panties",
          "bra",
          "pants",
          "leggings",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-13-min.png",
        link: "https://amzn.to/49PRvPu",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
          "bags",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-13-1.png",
        link: "https://amzn.to/3uN5QNE",
      },
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
          "kits",
          "facial",
          "rash",
          "cream",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-14-1.png",
        link: "https://amzn.to/3TeQBGw",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
          "belly",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-15.png",
        link: "https://amzn.to/3uEtzjb",
      },
    ],
  },
  {
    week: 37,
    slides: [
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-11-1.png",
        link: "https://amzn.to/49OOChX",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
          "bags",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-12-1.png",
        link: "https://amzn.to/42NwmTK",
      },
      {
        keywords: ["bottle", "water", "brass", "container", "ghada"],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-13-1.png",
        link: "https://amzn.to/4bJEzMF",
      },
      {
        keywords: ["baby", "shower", "god", "bharai", "godbharai"],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-15-1.png",
        link: "https://amzn.to/3wvIsVC",
      },
      {
        keywords: [
          "breastfeeding",
          "accessories",
          "nursing",
          "breast",
          "pads",
          "bras",
          "pillows",
          "breast",
          "pumps",
          "nipple",
          "shields",
          "milk",
          "storage",
          "bags",
          "covers",
          "creams",
        ],
        image:
          "https://d6pfd7iaw5kbr.cloudfront.net/wp-content/uploads/2024/02/Dhamaal-Discounts-T2a-16-1.png",
        link: "https://amzn.to/3uQeo6j",
      },
    ],
  },
  {
    week: 38,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-12-1.png",
        link: "https://amzn.to/4c5DCib",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "tops",
          "panties",
          "bra",
          "pants",
          "leggings",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-13-min.png",
        link: "https://amzn.to/49PRvPu",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
          "bags",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-13-1.png",
        link: "https://amzn.to/3uN5QNE",
      },
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
          "kits",
          "facial",
          "rash",
          "cream",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-14-1.png",
        link: "https://amzn.to/3TeQBGw",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
          "belly",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-15.png",
        link: "https://amzn.to/3uEtzjb",
      },
    ],
  },
  {
    week: 39,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-12-1.png",
        link: "https://amzn.to/4c5DCib",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "tops",
          "panties",
          "bra",
          "pants",
          "leggings",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-13-min.png",
        link: "https://amzn.to/49PRvPu",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
          "bags",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-13-1.png",
        link: "https://amzn.to/3uN5QNE",
      },
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
          "kits",
          "facial",
          "rash",
          "cream",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-14-1.png",
        link: "https://amzn.to/3TeQBGw",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
          "belly",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-15.png",
        link: "https://amzn.to/3uEtzjb",
      },
    ],
  },
  {
    week: 40,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-12-1.png",
        link: "https://amzn.to/4c5DCib",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "tops",
          "panties",
          "bra",
          "pants",
          "leggings",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-13-min.png",
        link: "https://amzn.to/49PRvPu",
      },
      {
        keywords: [
          "home",
          "organizer",
          "storage",
          "solutions",
          "closet",
          "organizers",
          "bins",
          "drawer",
          "shelving",
          "units",
          "wardrobe",
          "basket",
          "underbed",
          "garage",
          "bags",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-13-1.png",
        link: "https://amzn.to/3uN5QNE",
      },
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
          "kits",
          "facial",
          "rash",
          "cream",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-14-1.png",
        link: "https://amzn.to/3TeQBGw",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
          "belly",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-15.png",
        link: "https://amzn.to/3uEtzjb",
      },
    ],
  },
];

export const mothersStoreImages = [
  {
    week: 4,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-8-1.png",
        link: "https://amzn.to/3S08A34",
      },
      {
        keywords: [
          "bar",
          "soap",
          "handmade",
          "handcrafted",
          "organic",
          "natural",
          "sensitive",
          "skin",
          "sebamed",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-9-1.png",
        link: "https://amzn.to/41HNDgp",
      },
      {
        keywords: [
          "hair",
          "care",
          "coconut",
          "olive",
          "neem",
          "teatree",
          "bhringraj",
          "oil",
          "onion",
          "adivasi",
          "herbal",
          "buddha",
          "Khadi",
          "amla",
          "pumpkin",
          "jojoba",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-10-2.png",
        link: "https://amzn.to/4aMujTr",
      },
      {
        keywords: [
          "cotton",
          "ethnic",
          "dress",
          "suit",
          "salwar",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-11-1.png",
        link: "https://amzn.to/3vv6LT1",
      },
      {
        keywords: ["fashion", "tops", "t-shirts", "blouse", "clothes", "cloth"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-12-1.png",
        link: "https://amzn.to/41YuWWd",
      },
    ],
  },
  {
    week: 5,
    slides: [
      {
        keywords: [
          "mamaearth",
          "mama",
          "earth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "de",
          "tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-1-1.png",
        link: "https://amzn.to/3NRS9U3",
      },
      {
        keywords: [
          "mamaearth",
          "mama",
          "earth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "de",
          "tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-2-1.png",
        link: "https://amzn.to/3RPbNBd",
      },
      {
        keywords: [
          "hair",
          "serum",
          "tresemme",
          "wishcare",
          "biotique",
          "growth",
          "best",
          "streax",
          "cuticapil",
          "pilgrim",
          "redensyl",
          "anagain",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-3-1.png",
        link: "https://amzn.to/3NUTqK7",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-4-1-1-1.png",
        link: "https://amzn.to/3SWSDLl",
      },
      {
        keywords: [
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-5-1.png",
        link: "https://amzn.to/3NWe7Fz",
      },
    ],
  },
  {
    week: 6,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-8-1.png",
        link: "https://amzn.to/3S08A34",
      },
      {
        keywords: [
          "bar",
          "soap",
          "handmade",
          "handcrafted",
          "organic",
          "natural",
          "sensitive",
          "skin",
          "sebamed",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-9-1.png",
        link: "https://amzn.to/41HNDgp",
      },
      {
        keywords: [
          "hair",
          "care",
          "coconut",
          "olive",
          "neem",
          "teatree",
          "bhringraj",
          "oil",
          "onion",
          "adivasi",
          "herbal",
          "buddha",
          "Khadi",
          "amla",
          "pumpkin",
          "jojoba",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-10-2.png",
        link: "https://amzn.to/4aMujTr",
      },
      {
        keywords: [
          "cotton",
          "ethnic",
          "dress",
          "suit",
          "salwar",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-11-1.png",
        link: "https://amzn.to/3vv6LT1",
      },
      {
        keywords: ["fashion", "tops", "t-shirts", "blouse", "clothes", "cloth"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-12-1.png",
        link: "https://amzn.to/41YuWWd",
      },
    ],
  },
  {
    week: 7,
    slides: [
      {
        keywords: [
          "mamaearth",
          "mama",
          "earth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "de",
          "tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-1-1.png",
        link: "https://amzn.to/3NRS9U3",
      },
      {
        keywords: [
          "mamaearth",
          "mama",
          "earth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "de",
          "tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-2-1.png",
        link: "https://amzn.to/3RPbNBd",
      },
      {
        keywords: [
          "hair",
          "serum",
          "tresemme",
          "wishcare",
          "biotique",
          "growth",
          "best",
          "streax",
          "cuticapil",
          "pilgrim",
          "redensyl",
          "anagain",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-3-1.png",
        link: "https://amzn.to/3NUTqK7",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-4-1-1-1.png",
        link: "https://amzn.to/3SWSDLl",
      },
      {
        keywords: [
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-5-1.png",
        link: "https://amzn.to/3NWe7Fz",
      },
    ],
  },
  {
    week: 8,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-8-1.png",
        link: "https://amzn.to/3S08A34",
      },
      {
        keywords: [
          "bar",
          "soap",
          "handmade",
          "handcrafted",
          "organic",
          "natural",
          "sensitive",
          "skin",
          "sebamed",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-9-1.png",
        link: "https://amzn.to/41HNDgp",
      },
      {
        keywords: [
          "hair",
          "care",
          "coconut",
          "olive",
          "neem",
          "teatree",
          "bhringraj",
          "oil",
          "onion",
          "adivasi",
          "herbal",
          "buddha",
          "Khadi",
          "amla",
          "pumpkin",
          "jojoba",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-10-2.png",
        link: "https://amzn.to/4aMujTr",
      },
      {
        keywords: [
          "cotton",
          "ethnic",
          "dress",
          "suit",
          "salwar",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-11-1.png",
        link: "https://amzn.to/3vv6LT1",
      },
      {
        keywords: ["fashion", "tops", "t-shirts", "blouse", "clothes", "cloth"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-12-1.png",
        link: "https://amzn.to/41YuWWd",
      },
    ],
  },
  {
    week: 9,
    slides: [
      {
        keywords: [
          "mamaearth",
          "mama",
          "earth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "de",
          "tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-1-1.png",
        link: "https://amzn.to/3NRS9U3",
      },
      {
        keywords: [
          "mamaearth",
          "mama",
          "earth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "de",
          "tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-2-1.png",
        link: "https://amzn.to/3RPbNBd",
      },
      {
        keywords: [
          "hair",
          "serum",
          "tresemme",
          "wishcare",
          "biotique",
          "growth",
          "best",
          "streax",
          "cuticapil",
          "pilgrim",
          "redensyl",
          "anagain",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-3-1.png",
        link: "https://amzn.to/3NUTqK7",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-4-1-1-1.png",
        link: "https://amzn.to/3SWSDLl",
      },
      {
        keywords: [
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-5-1.png",
        link: "https://amzn.to/3NWe7Fz",
      },
    ],
  },
  {
    week: 10,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-8-1.png",
        link: "https://amzn.to/3S08A34",
      },
      {
        keywords: [
          "bar",
          "soap",
          "handmade",
          "handcrafted",
          "organic",
          "natural",
          "sensitive",
          "skin",
          "sebamed",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-9-1.png",
        link: "https://amzn.to/41HNDgp",
      },
      {
        keywords: [
          "hair",
          "care",
          "coconut",
          "olive",
          "neem",
          "teatree",
          "bhringraj",
          "oil",
          "onion",
          "adivasi",
          "herbal",
          "buddha",
          "Khadi",
          "amla",
          "pumpkin",
          "jojoba",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-10-2.png",
        link: "https://amzn.to/4aMujTr",
      },
      {
        keywords: [
          "cotton",
          "ethnic",
          "dress",
          "suit",
          "salwar",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-11-1.png",
        link: "https://amzn.to/3vv6LT1",
      },
      {
        keywords: ["fashion", "tops", "t-shirts", "blouse", "clothes", "cloth"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-12-1.png",
        link: "https://amzn.to/41YuWWd",
      },
    ],
  },
  {
    week: 11,
    slides: [
      {
        keywords: [
          "mamaearth",
          "mama",
          "earth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "de",
          "tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-1-1.png",
        link: "https://amzn.to/3NRS9U3",
      },
      {
        keywords: [
          "mamaearth",
          "mama",
          "earth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "de",
          "tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-2-1.png",
        link: "https://amzn.to/3RPbNBd",
      },
      {
        keywords: [
          "hair",
          "serum",
          "tresemme",
          "wishcare",
          "biotique",
          "growth",
          "best",
          "streax",
          "cuticapil",
          "pilgrim",
          "redensyl",
          "anagain",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-3-1.png",
        link: "https://amzn.to/3NUTqK7",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-4-1-1-1.png",
        link: "https://amzn.to/3SWSDLl",
      },
      {
        keywords: [
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-Mothers-Store-5-1.png",
        link: "https://amzn.to/3NWe7Fz",
      },
    ],
  },
  {
    week: 12,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-8-1.png",
        link: "https://amzn.to/3S08A34",
      },
      {
        keywords: [
          "bar",
          "soap",
          "handmade",
          "handcrafted",
          "organic",
          "natural",
          "sensitive",
          "skin",
          "sebamed",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-9-1.png",
        link: "https://amzn.to/41HNDgp",
      },
      {
        keywords: [
          "hair",
          "care",
          "coconut",
          "olive",
          "neem",
          "teatree",
          "bhringraj",
          "oil",
          "onion",
          "adivasi",
          "herbal",
          "buddha",
          "Khadi",
          "amla",
          "pumpkin",
          "jojoba",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-10-2.png",
        link: "https://amzn.to/4aMujTr",
      },
      {
        keywords: [
          "cotton",
          "ethnic",
          "dress",
          "suit",
          "salwar",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-11-1.png",
        link: "https://amzn.to/3vv6LT1",
      },
      {
        keywords: ["fashion", "tops", "t-shirts", "blouse", "clothes", "cloth"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-12-1.png",
        link: "https://amzn.to/41YuWWd",
      },
    ],
  },
  {
    week: 13,
    slides: [
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-1.png",
        link: "https://amzn.to/4bAcKGv",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-2-1.png",
        link: "https://amzn.to/3we7hFh",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-3-1.png",
        link: "https://amzn.to/3UFKY5H",
      },
      {
        keywords: [
          "pregnancy",
          "planner",
          "sticker",
          "cards",
          "maternity",
          "diary",
          "journal",
          "gift",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-4-1.png",
        link: "https://amzn.to/3waV5VP",
      },
      {
        keywords: [
          "dabur",
          "chyawanprash",
          "immunity",
          "ayurveda",
          "heart",
          "health",
          "cholestrol",
          "care",
          "diabetic",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-5-1.png",
        link: "https://amzn.to/3usFBfj",
      },
    ],
  },
  {
    week: 14,
    slides: [
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-1.png",
        link: "https://amzn.to/4bAcKGv",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-2-1.png",
        link: "https://amzn.to/3we7hFh",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-3-1.png",
        link: "https://amzn.to/3UFKY5H",
      },
      {
        keywords: [
          "pregnancy",
          "planner",
          "sticker",
          "cards",
          "maternity",
          "diary",
          "journal",
          "gift",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-4-1.png",
        link: "https://amzn.to/3waV5VP",
      },
      {
        keywords: [
          "dabur",
          "chyawanprash",
          "immunity",
          "ayurveda",
          "heart",
          "health",
          "cholestrol",
          "care",
          "diabetic",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-5-1.png",
        link: "https://amzn.to/3usFBfj",
      },
    ],
  },
  {
    week: 15,
    slides: [
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-1.png",
        link: "https://amzn.to/4bAcKGv",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-2-1.png",
        link: "https://amzn.to/3we7hFh",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-3-1.png",
        link: "https://amzn.to/3UFKY5H",
      },
      {
        keywords: [
          "pregnancy",
          "planner",
          "sticker",
          "cards",
          "maternity",
          "diary",
          "journal",
          "gift",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-4-1.png",
        link: "https://amzn.to/3waV5VP",
      },
      {
        keywords: [
          "dabur",
          "chyawanprash",
          "immunity",
          "ayurveda",
          "heart",
          "health",
          "cholestrol",
          "care",
          "diabetic",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-5-1.png",
        link: "https://amzn.to/3usFBfj",
      },
    ],
  },
  {
    week: 16,
    slides: [
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-1.png",
        link: "https://amzn.to/4bAcKGv",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-2-1.png",
        link: "https://amzn.to/3we7hFh",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-3-1.png",
        link: "https://amzn.to/3UFKY5H",
      },
      {
        keywords: [
          "pregnancy",
          "planner",
          "sticker",
          "cards",
          "maternity",
          "diary",
          "journal",
          "gift",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-4-1.png",
        link: "https://amzn.to/3waV5VP",
      },
      {
        keywords: [
          "dabur",
          "chyawanprash",
          "immunity",
          "ayurveda",
          "heart",
          "health",
          "cholestrol",
          "care",
          "diabetic",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-5-1.png",
        link: "https://amzn.to/3usFBfj",
      },
    ],
  },
  {
    week: 17,
    slides: [
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-1.png",
        link: "https://amzn.to/4bAcKGv",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-2-1.png",
        link: "https://amzn.to/3we7hFh",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-3-1.png",
        link: "https://amzn.to/3UFKY5H",
      },
      {
        keywords: [
          "pregnancy",
          "planner",
          "sticker",
          "cards",
          "maternity",
          "diary",
          "journal",
          "gift",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-4-1.png",
        link: "https://amzn.to/3waV5VP",
      },
      {
        keywords: [
          "dabur",
          "chyawanprash",
          "immunity",
          "ayurveda",
          "heart",
          "health",
          "cholestrol",
          "care",
          "diabetic",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-5-1.png",
        link: "https://amzn.to/3usFBfj",
      },
    ],
  },
  {
    week: 18,
    slides: [
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-1.png",
        link: "https://amzn.to/4bAcKGv",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-2-1.png",
        link: "https://amzn.to/3we7hFh",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-3-1.png",
        link: "https://amzn.to/3UFKY5H",
      },
      {
        keywords: [
          "pregnancy",
          "planner",
          "sticker",
          "cards",
          "maternity",
          "diary",
          "journal",
          "gift",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-4-1.png",
        link: "https://amzn.to/3waV5VP",
      },
      {
        keywords: [
          "dabur",
          "chyawanprash",
          "immunity",
          "ayurveda",
          "heart",
          "health",
          "cholestrol",
          "care",
          "diabetic",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-5-1.png",
        link: "https://amzn.to/3usFBfj",
      },
    ],
  },
  {
    week: 19,
    slides: [
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-1.png",
        link: "https://amzn.to/4bAcKGv",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-2-1.png",
        link: "https://amzn.to/3we7hFh",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-3-1.png",
        link: "https://amzn.to/3UFKY5H",
      },
      {
        keywords: [
          "pregnancy",
          "planner",
          "sticker",
          "cards",
          "maternity",
          "diary",
          "journal",
          "gift",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-4-1.png",
        link: "https://amzn.to/3waV5VP",
      },
      {
        keywords: [
          "dabur",
          "chyawanprash",
          "immunity",
          "ayurveda",
          "heart",
          "health",
          "cholestrol",
          "care",
          "diabetic",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/New-HerStore-T2-Mothers-Store-5-1.png",
        link: "https://amzn.to/3usFBfj",
      },
    ],
  },
  {
    week: 20,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-1-1.png",
        link: "https://amzn.to/3wwr2I5",
      },
      {
        keywords: [
          "best",
          "body",
          "scrub",
          "for",
          "skin",
          "whitening",
          "mCaffeine",
          "beauty",
          "face",
          "mask",
          "cloth",
          "natural",
          "scrub",
          "wash",
          "and",
          "loofah",
          "exfoliating",
          "sugar",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-2-1.png",
        link: "https://amzn.to/3I9ausy",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long-lasting",
          "matte",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "makeup",
          "puff",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-3-1.png",
        link: "https://amzn.to/3I5ZE6j",
      },
      {
        keywords: [
          "body",
          "support",
          "belly",
          "back",
          "pillow",
          "memory",
          "foam",
          "leg",
          "side",
          "sleep",
          "knee",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-4-1.png",
        link: "https://amzn.to/3OPUA9X",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "iron",
          "zinc",
          "vitamin",
          "vitals",
          "B12",
          "D",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-5-1.png",
        link: "https://amzn.to/3ORPjyH",
      },
    ],
  },
  {
    week: 21,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-1-1.png",
        link: "https://amzn.to/3wwr2I5",
      },
      {
        keywords: [
          "best",
          "body",
          "scrub",
          "for",
          "skin",
          "whitening",
          "mCaffeine",
          "beauty",
          "face",
          "mask",
          "cloth",
          "natural",
          "scrub",
          "wash",
          "and",
          "loofah",
          "exfoliating",
          "sugar",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-2-1.png",
        link: "https://amzn.to/3I9ausy",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long-lasting",
          "matte",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "makeup",
          "puff",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-3-1.png",
        link: "https://amzn.to/3I5ZE6j",
      },
      {
        keywords: [
          "body",
          "support",
          "belly",
          "back",
          "pillow",
          "memory",
          "foam",
          "leg",
          "side",
          "sleep",
          "knee",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-4-1.png",
        link: "https://amzn.to/3OPUA9X",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "iron",
          "zinc",
          "vitamin",
          "vitals",
          "B12",
          "D",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-5-1.png",
        link: "https://amzn.to/3ORPjyH",
      },
    ],
  },
  {
    week: 22,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-1-1.png",
        link: "https://amzn.to/3wwr2I5",
      },
      {
        keywords: [
          "best",
          "body",
          "scrub",
          "for",
          "skin",
          "whitening",
          "mCaffeine",
          "beauty",
          "face",
          "mask",
          "cloth",
          "natural",
          "scrub",
          "wash",
          "and",
          "loofah",
          "exfoliating",
          "sugar",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-2-1.png",
        link: "https://amzn.to/3I9ausy",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long-lasting",
          "matte",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "makeup",
          "puff",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-3-1.png",
        link: "https://amzn.to/3I5ZE6j",
      },
      {
        keywords: [
          "body",
          "support",
          "belly",
          "back",
          "pillow",
          "memory",
          "foam",
          "leg",
          "side",
          "sleep",
          "knee",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-4-1.png",
        link: "https://amzn.to/3OPUA9X",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "iron",
          "zinc",
          "vitamin",
          "vitals",
          "B12",
          "D",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-5-1.png",
        link: "https://amzn.to/3ORPjyH",
      },
    ],
  },
  {
    week: 23,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-1-1.png",
        link: "https://amzn.to/3wwr2I5",
      },
      {
        keywords: [
          "best",
          "body",
          "scrub",
          "for",
          "skin",
          "whitening",
          "mCaffeine",
          "beauty",
          "face",
          "mask",
          "cloth",
          "natural",
          "scrub",
          "wash",
          "and",
          "loofah",
          "exfoliating",
          "sugar",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-2-1.png",
        link: "https://amzn.to/3I9ausy",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long-lasting",
          "matte",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "makeup",
          "puff",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-3-1.png",
        link: "https://amzn.to/3I5ZE6j",
      },
      {
        keywords: [
          "body",
          "support",
          "belly",
          "back",
          "pillow",
          "memory",
          "foam",
          "leg",
          "side",
          "sleep",
          "knee",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-4-1.png",
        link: "https://amzn.to/3OPUA9X",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "iron",
          "zinc",
          "vitamin",
          "vitals",
          "B12",
          "D",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-5-1.png",
        link: "https://amzn.to/3ORPjyH",
      },
    ],
  },
  {
    week: 24,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-1-1.png",
        link: "https://amzn.to/3wwr2I5",
      },
      {
        keywords: [
          "best",
          "body",
          "scrub",
          "for",
          "skin",
          "whitening",
          "mCaffeine",
          "beauty",
          "face",
          "mask",
          "cloth",
          "natural",
          "scrub",
          "wash",
          "and",
          "loofah",
          "exfoliating",
          "sugar",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-2-1.png",
        link: "https://amzn.to/3I9ausy",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long-lasting",
          "matte",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "makeup",
          "puff",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-3-1.png",
        link: "https://amzn.to/3I5ZE6j",
      },
      {
        keywords: [
          "body",
          "support",
          "belly",
          "back",
          "pillow",
          "memory",
          "foam",
          "leg",
          "side",
          "sleep",
          "knee",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-4-1.png",
        link: "https://amzn.to/3OPUA9X",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "iron",
          "zinc",
          "vitamin",
          "vitals",
          "B12",
          "D",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-5-1.png",
        link: "https://amzn.to/3ORPjyH",
      },
    ],
  },
  {
    week: 25,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-1-1.png",
        link: "https://amzn.to/3wwr2I5",
      },
      {
        keywords: [
          "best",
          "body",
          "scrub",
          "for",
          "skin",
          "whitening",
          "mCaffeine",
          "beauty",
          "face",
          "mask",
          "cloth",
          "natural",
          "scrub",
          "wash",
          "and",
          "loofah",
          "exfoliating",
          "sugar",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-2-1.png",
        link: "https://amzn.to/3I9ausy",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long-lasting",
          "matte",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "makeup",
          "puff",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-3-1.png",
        link: "https://amzn.to/3I5ZE6j",
      },
      {
        keywords: [
          "body",
          "support",
          "belly",
          "back",
          "pillow",
          "memory",
          "foam",
          "leg",
          "side",
          "sleep",
          "knee",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-4-1.png",
        link: "https://amzn.to/3OPUA9X",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "iron",
          "zinc",
          "vitamin",
          "vitals",
          "B12",
          "D",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-5-1.png",
        link: "https://amzn.to/3ORPjyH",
      },
    ],
  },
  {
    week: 26,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-1-1.png",
        link: "https://amzn.to/3wwr2I5",
      },
      {
        keywords: [
          "best",
          "body",
          "scrub",
          "for",
          "skin",
          "whitening",
          "mCaffeine",
          "beauty",
          "face",
          "mask",
          "cloth",
          "natural",
          "scrub",
          "wash",
          "and",
          "loofah",
          "exfoliating",
          "sugar",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-2-1.png",
        link: "https://amzn.to/3I9ausy",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long-lasting",
          "matte",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "makeup",
          "puff",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-3-1.png",
        link: "https://amzn.to/3I5ZE6j",
      },
      {
        keywords: [
          "body",
          "support",
          "belly",
          "back",
          "pillow",
          "memory",
          "foam",
          "leg",
          "side",
          "sleep",
          "knee",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-4-1.png",
        link: "https://amzn.to/3OPUA9X",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "iron",
          "zinc",
          "vitamin",
          "vitals",
          "B12",
          "D",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-5-1.png",
        link: "https://amzn.to/3ORPjyH",
      },
    ],
  },
  {
    week: 27,
    slides: [
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
          "dresses",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-13-min.png",
        link: "https://amzn.to/3tO27Pq",
      },
      {
        keywords: [
          "wellness",
          "fat",
          "burner",
          "iron",
          "folic",
          "acid",
          "supplement",
          "constipation",
          "women's",
          "daily",
          "detox",
          "muscle",
          "joint",
          "pain",
          "fast",
          "relief",
          "ointment",
          "immunity",
          "amla",
          "collagen",
          "builder",
          "anti",
          "acne",
          "blood",
          "purifier",
          "pigmentation",
          "neem",
          "calcium",
          "vitamin",
          "d3",
          "spinach",
          "drops",
          "sleep",
          "support",
          "stress",
          "thyroid",
          "liver",
          "ayurvedic",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-14-min.png",
        link: "https://amzn.to/48LvTTH",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-15-min.png",
        link: "https://amzn.to/3NWZ7Y2",
      },
      {
        keywords: [
          "baby",
          "wash",
          "soap",
          "shampoo",
          "lotion",
          "cream",
          "kajal",
          "himalaya",
          "mumma",
          "earth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-16-min.png",
        link: "https://amzn.to/41V9eC4",
      },
      {
        keywords: [
          "baby",
          "bedding",
          "nursery",
          "crib",
          "blankets",
          "decor",
          "room",
          "furniture",
          "crib",
          "mattress",
          "accessories",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-17-min.png",
        link: "https://amzn.to/3OS6y2W",
      },
    ],
  },
  {
    week: 28,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-6-1-1.png",
        link: "https://amzn.to/3HeRet3",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-7-1.png",
        link: "https://amzn.to/3Ig4jmA",
      },
      {
        keywords: [
          "earring",
          "bracelet",
          "payal",
          "ancklet",
          "jwellary",
          "najariya",
          "kids",
          "baby",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-8-1.png",
        link: "https://amzn.to/3IcGCv4",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-9-1.png",
        link: "https://amzn.to/49rQ7CF",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-10-1.png",
        link: "https://amzn.to/3uOTk08",
      },
    ],
  },
  {
    week: 29,
    slides: [
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
          "dresses",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-13-min.png",
        link: "https://amzn.to/3tO27Pq",
      },
      {
        keywords: [
          "wellness",
          "fat",
          "burner",
          "iron",
          "folic",
          "acid",
          "supplement",
          "constipation",
          "women's",
          "daily",
          "detox",
          "muscle",
          "joint",
          "pain",
          "fast",
          "relief",
          "ointment",
          "immunity",
          "amla",
          "collagen",
          "builder",
          "anti",
          "acne",
          "blood",
          "purifier",
          "pigmentation",
          "neem",
          "calcium",
          "vitamin",
          "d3",
          "spinach",
          "drops",
          "sleep",
          "support",
          "stress",
          "thyroid",
          "liver",
          "ayurvedic",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-14-min.png",
        link: "https://amzn.to/48LvTTH",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-15-min.png",
        link: "https://amzn.to/3NWZ7Y2",
      },
      {
        keywords: [
          "baby",
          "wash",
          "soap",
          "shampoo",
          "lotion",
          "cream",
          "kajal",
          "himalaya",
          "mumma",
          "earth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-16-min.png",
        link: "https://amzn.to/41V9eC4",
      },
      {
        keywords: [
          "baby",
          "bedding",
          "nursery",
          "crib",
          "blankets",
          "decor",
          "room",
          "furniture",
          "crib",
          "mattress",
          "accessories",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-17-min.png",
        link: "https://amzn.to/3OS6y2W",
      },
    ],
  },
  {
    week: 30,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-6-1-1.png",
        link: "https://amzn.to/3HeRet3",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-7-1.png",
        link: "https://amzn.to/3Ig4jmA",
      },
      {
        keywords: [
          "earring",
          "bracelet",
          "payal",
          "ancklet",
          "jwellary",
          "najariya",
          "kids",
          "baby",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-8-1.png",
        link: "https://amzn.to/3IcGCv4",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-9-1.png",
        link: "https://amzn.to/49rQ7CF",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-10-1.png",
        link: "https://amzn.to/3uOTk08",
      },
    ],
  },
  {
    week: 31,
    slides: [
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
          "dresses",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-13-min.png",
        link: "https://amzn.to/3tO27Pq",
      },
      {
        keywords: [
          "wellness",
          "fat",
          "burner",
          "iron",
          "folic",
          "acid",
          "supplement",
          "constipation",
          "women's",
          "daily",
          "detox",
          "muscle",
          "joint",
          "pain",
          "fast",
          "relief",
          "ointment",
          "immunity",
          "amla",
          "collagen",
          "builder",
          "anti",
          "acne",
          "blood",
          "purifier",
          "pigmentation",
          "neem",
          "calcium",
          "vitamin",
          "d3",
          "spinach",
          "drops",
          "sleep",
          "support",
          "stress",
          "thyroid",
          "liver",
          "ayurvedic",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-14-min.png",
        link: "https://amzn.to/48LvTTH",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-15-min.png",
        link: "https://amzn.to/3NWZ7Y2",
      },
      {
        keywords: [
          "baby",
          "wash",
          "soap",
          "shampoo",
          "lotion",
          "cream",
          "kajal",
          "himalaya",
          "mumma",
          "earth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-16-min.png",
        link: "https://amzn.to/41V9eC4",
      },
      {
        keywords: [
          "baby",
          "bedding",
          "nursery",
          "crib",
          "blankets",
          "decor",
          "room",
          "furniture",
          "crib",
          "mattress",
          "accessories",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-17-min.png",
        link: "https://amzn.to/3OS6y2W",
      },
    ],
  },
  {
    week: 32,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-6-1-1.png",
        link: "https://amzn.to/3HeRet3",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-7-1.png",
        link: "https://amzn.to/3Ig4jmA",
      },
      {
        keywords: [
          "earring",
          "bracelet",
          "payal",
          "ancklet",
          "jwellary",
          "najariya",
          "kids",
          "baby",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-8-1.png",
        link: "https://amzn.to/3IcGCv4",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-9-1.png",
        link: "https://amzn.to/49rQ7CF",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-10-1.png",
        link: "https://amzn.to/3uOTk08",
      },
    ],
  },
  {
    week: 33,
    slides: [
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
          "dresses",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-13-min.png",
        link: "https://amzn.to/3tO27Pq",
      },
      {
        keywords: [
          "wellness",
          "fat",
          "burner",
          "iron",
          "folic",
          "acid",
          "supplement",
          "constipation",
          "women's",
          "daily",
          "detox",
          "muscle",
          "joint",
          "pain",
          "fast",
          "relief",
          "ointment",
          "immunity",
          "amla",
          "collagen",
          "builder",
          "anti",
          "acne",
          "blood",
          "purifier",
          "pigmentation",
          "neem",
          "calcium",
          "vitamin",
          "d3",
          "spinach",
          "drops",
          "sleep",
          "support",
          "stress",
          "thyroid",
          "liver",
          "ayurvedic",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-14-min.png",
        link: "https://amzn.to/48LvTTH",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-15-min.png",
        link: "https://amzn.to/3NWZ7Y2",
      },
      {
        keywords: [
          "baby",
          "wash",
          "soap",
          "shampoo",
          "lotion",
          "cream",
          "kajal",
          "himalaya",
          "mumma",
          "earth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-16-min.png",
        link: "https://amzn.to/41V9eC4",
      },
      {
        keywords: [
          "baby",
          "bedding",
          "nursery",
          "crib",
          "blankets",
          "decor",
          "room",
          "furniture",
          "crib",
          "mattress",
          "accessories",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-17-min.png",
        link: "https://amzn.to/3OS6y2W",
      },
    ],
  },
  {
    week: 34,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-6-1-1.png",
        link: "https://amzn.to/3HeRet3",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-7-1.png",
        link: "https://amzn.to/3Ig4jmA",
      },
      {
        keywords: [
          "earring",
          "bracelet",
          "payal",
          "ancklet",
          "jwellary",
          "najariya",
          "kids",
          "baby",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-8-1.png",
        link: "https://amzn.to/3IcGCv4",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-9-1.png",
        link: "https://amzn.to/49rQ7CF",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-10-1.png",
        link: "https://amzn.to/3uOTk08",
      },
    ],
  },
  {
    week: 35,
    slides: [
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
          "dresses",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-13-min.png",
        link: "https://amzn.to/3tO27Pq",
      },
      {
        keywords: [
          "wellness",
          "fat",
          "burner",
          "iron",
          "folic",
          "acid",
          "supplement",
          "constipation",
          "women's",
          "daily",
          "detox",
          "muscle",
          "joint",
          "pain",
          "fast",
          "relief",
          "ointment",
          "immunity",
          "amla",
          "collagen",
          "builder",
          "anti",
          "acne",
          "blood",
          "purifier",
          "pigmentation",
          "neem",
          "calcium",
          "vitamin",
          "d3",
          "spinach",
          "drops",
          "sleep",
          "support",
          "stress",
          "thyroid",
          "liver",
          "ayurvedic",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-14-min.png",
        link: "https://amzn.to/48LvTTH",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-15-min.png",
        link: "https://amzn.to/3NWZ7Y2",
      },
      {
        keywords: [
          "baby",
          "wash",
          "soap",
          "shampoo",
          "lotion",
          "cream",
          "kajal",
          "himalaya",
          "mumma",
          "earth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-16-min.png",
        link: "https://amzn.to/41V9eC4",
      },
      {
        keywords: [
          "baby",
          "bedding",
          "nursery",
          "crib",
          "blankets",
          "decor",
          "room",
          "furniture",
          "crib",
          "mattress",
          "accessories",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-17-min.png",
        link: "https://amzn.to/3OS6y2W",
      },
    ],
  },
  {
    week: 36,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-6-1-1.png",
        link: "https://amzn.to/3HeRet3",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-7-1.png",
        link: "https://amzn.to/3Ig4jmA",
      },
      {
        keywords: [
          "earring",
          "bracelet",
          "payal",
          "ancklet",
          "jwellary",
          "najariya",
          "kids",
          "baby",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-8-1.png",
        link: "https://amzn.to/3IcGCv4",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-9-1.png",
        link: "https://amzn.to/49rQ7CF",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-10-1.png",
        link: "https://amzn.to/3uOTk08",
      },
    ],
  },
  {
    week: 37,
    slides: [
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
          "dresses",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-13-min.png",
        link: "https://amzn.to/3tO27Pq",
      },
      {
        keywords: [
          "wellness",
          "fat",
          "burner",
          "iron",
          "folic",
          "acid",
          "supplement",
          "constipation",
          "women's",
          "daily",
          "detox",
          "muscle",
          "joint",
          "pain",
          "fast",
          "relief",
          "ointment",
          "immunity",
          "amla",
          "collagen",
          "builder",
          "anti",
          "acne",
          "blood",
          "purifier",
          "pigmentation",
          "neem",
          "calcium",
          "vitamin",
          "d3",
          "spinach",
          "drops",
          "sleep",
          "support",
          "stress",
          "thyroid",
          "liver",
          "ayurvedic",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-14-min.png",
        link: "https://amzn.to/48LvTTH",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-15-min.png",
        link: "https://amzn.to/3NWZ7Y2",
      },
      {
        keywords: [
          "baby",
          "wash",
          "soap",
          "shampoo",
          "lotion",
          "cream",
          "kajal",
          "himalaya",
          "mumma",
          "earth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-16-min.png",
        link: "https://amzn.to/41V9eC4",
      },
      {
        keywords: [
          "baby",
          "bedding",
          "nursery",
          "crib",
          "blankets",
          "decor",
          "room",
          "furniture",
          "crib",
          "mattress",
          "accessories",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-3-17-min.png",
        link: "https://amzn.to/3OS6y2W",
      },
    ],
  },
  {
    week: 38,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-6-1-1.png",
        link: "https://amzn.to/3HeRet3",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-7-1.png",
        link: "https://amzn.to/3Ig4jmA",
      },
      {
        keywords: [
          "earring",
          "bracelet",
          "payal",
          "ancklet",
          "jwellary",
          "najariya",
          "kids",
          "baby",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-8-1.png",
        link: "https://amzn.to/3IcGCv4",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-9-1.png",
        link: "https://amzn.to/49rQ7CF",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-10-1.png",
        link: "https://amzn.to/3uOTk08",
      },
    ],
  },
  {
    week: 39,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-6-1-1.png",
        link: "https://amzn.to/3HeRet3",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-7-1.png",
        link: "https://amzn.to/3Ig4jmA",
      },
      {
        keywords: [
          "earring",
          "bracelet",
          "payal",
          "ancklet",
          "jwellary",
          "najariya",
          "kids",
          "baby",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-8-1.png",
        link: "https://amzn.to/3IcGCv4",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-9-1.png",
        link: "https://amzn.to/49rQ7CF",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-10-1.png",
        link: "https://amzn.to/3uOTk08",
      },
    ],
  },
  {
    week: 40,
    slides: [
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-6-1-1.png",
        link: "https://amzn.to/3HeRet3",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
          "pants",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-7-1.png",
        link: "https://amzn.to/3Ig4jmA",
      },
      {
        keywords: [
          "earring",
          "bracelet",
          "payal",
          "ancklet",
          "jwellary",
          "najariya",
          "kids",
          "baby",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-8-1.png",
        link: "https://amzn.to/3IcGCv4",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-9-1.png",
        link: "https://amzn.to/49rQ7CF",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Mothers-Store-10-1.png",
        link: "https://amzn.to/3uOTk08",
      },
    ],
  },
];

export const seasonsHotDealsImages = [
  {
    week: 4,
    slides: [
      {
        keywords: [
          "winter",
          "warmer",
          "thermal",
          "sweater",
          "zipper",
          "hoodie",
          "sweatshirt",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/48VFuHG",
      },
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-2.png",
        link: "https://amzn.to/48W203d",
      },
      {
        keywords: [
          "pregnancy",
          "pants",
          "seamless",
          "legging",
          "leggings",
          "night",
          "dress",
          "nighty",
          "gown",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-3-1.png",
        link: "https://amzn.to/3Oacvb0",
      },
      {
        keywords: ["iron", "zinc", "vitamin", "vitals", "B12", "D", "tablets"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-4.png",
        link: "https://amzn.to/3SnYQj9",
      },
    ],
  },
  {
    week: 5,
    slides: [
      {
        keywords: ["yoga", "mat", "exercise"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-1.png",
        link: "https://amzn.to/3SZk87j",
      },
      {
        keywords: [
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "dress",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-2-1.png",
        link: "https://amzn.to/3SBTjVg",
      },
      {
        keywords: ["cotton", "scarf", "stoles", "scarves", "long"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-3-1.png",
        link: "https://amzn.to/3uvMFb6",
      },
      {
        keywords: ["water", "bottle", "bottles", "containers", "air", "tight"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-4-1.png",
        link: "https://amzn.to/3UDR4n5",
      },
    ],
  },
  {
    week: 6,
    slides: [
      {
        keywords: [
          "winter",
          "warmer",
          "thermal",
          "sweater",
          "zipper",
          "hoodie",
          "sweatshirt",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/48VFuHG",
      },
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-2.png",
        link: "https://amzn.to/48W203d",
      },
      {
        keywords: [
          "pregnancy",
          "pants",
          "seamless",
          "legging",
          "leggings",
          "night",
          "dress",
          "nighty",
          "gown",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-3-1.png",
        link: "https://amzn.to/3Oacvb0",
      },
      {
        keywords: ["iron", "zinc", "vitamin", "vitals", "B12", "D", "tablets"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-4.png",
        link: "https://amzn.to/3SnYQj9",
      },
    ],
  },
  {
    week: 7,
    slides: [
      {
        keywords: ["yoga", "mat", "exercise"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-1.png",
        link: "https://amzn.to/3SZk87j",
      },
      {
        keywords: [
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "dress",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-2-1.png",
        link: "https://amzn.to/3SBTjVg",
      },
      {
        keywords: ["cotton", "scarf", "stoles", "scarves", "long"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-3-1.png",
        link: "https://amzn.to/3uvMFb6",
      },
      {
        keywords: ["water", "bottle", "bottles", "containers", "air", "tight"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-4-1.png",
        link: "https://amzn.to/3UDR4n5",
      },
    ],
  },
  {
    week: 8,
    slides: [
      {
        keywords: [
          "winter",
          "warmer",
          "thermal",
          "sweater",
          "zipper",
          "hoodie",
          "sweatshirt",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/48VFuHG",
      },
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-2.png",
        link: "https://amzn.to/48W203d",
      },
      {
        keywords: [
          "pregnancy",
          "pants",
          "seamless",
          "legging",
          "leggings",
          "night",
          "dress",
          "nighty",
          "gown",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-3-1.png",
        link: "https://amzn.to/3Oacvb0",
      },
      {
        keywords: ["iron", "zinc", "vitamin", "vitals", "B12", "D", "tablets"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-4.png",
        link: "https://amzn.to/3SnYQj9",
      },
    ],
  },
  {
    week: 9,
    slides: [
      {
        keywords: ["yoga", "mat", "exercise"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-1.png",
        link: "https://amzn.to/3SZk87j",
      },
      {
        keywords: [
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "dress",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-2-1.png",
        link: "https://amzn.to/3SBTjVg",
      },
      {
        keywords: ["cotton", "scarf", "stoles", "scarves", "long"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-3-1.png",
        link: "https://amzn.to/3uvMFb6",
      },
      {
        keywords: ["water", "bottle", "bottles", "containers", "air", "tight"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-4-1.png",
        link: "https://amzn.to/3UDR4n5",
      },
    ],
  },
  {
    week: 10,
    slides: [
      {
        keywords: [
          "winter",
          "warmer",
          "thermal",
          "sweater",
          "zipper",
          "hoodie",
          "sweatshirt",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/48VFuHG",
      },
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-2.png",
        link: "https://amzn.to/48W203d",
      },
      {
        keywords: [
          "pregnancy",
          "pants",
          "seamless",
          "legging",
          "leggings",
          "night",
          "dress",
          "nighty",
          "gown",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-3-1.png",
        link: "https://amzn.to/3Oacvb0",
      },
      {
        keywords: ["iron", "zinc", "vitamin", "vitals", "B12", "D", "tablets"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-4.png",
        link: "https://amzn.to/3SnYQj9",
      },
    ],
  },
  {
    week: 11,
    slides: [
      {
        keywords: ["yoga", "mat", "exercise"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-1.png",
        link: "https://amzn.to/3SZk87j",
      },
      {
        keywords: [
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "dress",
          "clothes",
          "cloth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-2-1.png",
        link: "https://amzn.to/3SBTjVg",
      },
      {
        keywords: ["cotton", "scarf", "stoles", "scarves", "long"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-3-1.png",
        link: "https://amzn.to/3uvMFb6",
      },
      {
        keywords: ["water", "bottle", "bottles", "containers", "air", "tight"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-1-4-1.png",
        link: "https://amzn.to/3UDR4n5",
      },
    ],
  },
  {
    week: 12,
    slides: [
      {
        keywords: [
          "winter",
          "warmer",
          "thermal",
          "sweater",
          "zipper",
          "hoodie",
          "sweatshirt",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/48VFuHG",
      },
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-2.png",
        link: "https://amzn.to/48W203d",
      },
      {
        keywords: [
          "pregnancy",
          "pants",
          "seamless",
          "legging",
          "leggings",
          "night",
          "dress",
          "nighty",
          "gown",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-3-1.png",
        link: "https://amzn.to/3Oacvb0",
      },
      {
        keywords: ["iron", "zinc", "vitamin", "vitals", "B12", "D", "tablets"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-4.png",
        link: "https://amzn.to/3SnYQj9",
      },
    ],
  },
  {
    week: 13,
    slides: [
      {
        keywords: [
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "dress",
          "clothes",
          "cloth",
          "pure",
          "dresses",
          "summar",
          "tshirts",
          "t-shirt",
          "tops",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uFXXcQ",
      },
      {
        keywords: [
          "diet",
          "nutrition",
          "supplements",
          "protein",
          "vitamins",
          "minerals",
          "powder",
          "milk",
          "mix",
          "strength",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-2-1.png",
        link: "https://amzn.to/3T2i1iZ",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-4-1.png",
        link: "https://amzn.to/49GQd9u",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-6-1.png",
        link: "https://amzn.to/49lAfSa",
      },
    ],
  },
  {
    week: 14,
    slides: [
      {
        keywords: [
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "dress",
          "clothes",
          "cloth",
          "pure",
          "dresses",
          "summar",
          "tshirts",
          "t-shirt",
          "tops",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uFXXcQ",
      },
      {
        keywords: [
          "diet",
          "nutrition",
          "supplements",
          "protein",
          "vitamins",
          "minerals",
          "powder",
          "milk",
          "mix",
          "strength",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-2-1.png",
        link: "https://amzn.to/3T2i1iZ",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-4-1.png",
        link: "https://amzn.to/49GQd9u",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-6-1.png",
        link: "https://amzn.to/49lAfSa",
      },
    ],
  },
  {
    week: 15,
    slides: [
      {
        keywords: [
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "dress",
          "clothes",
          "cloth",
          "pure",
          "dresses",
          "summar",
          "tshirts",
          "t-shirt",
          "tops",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uFXXcQ",
      },
      {
        keywords: [
          "diet",
          "nutrition",
          "supplements",
          "protein",
          "vitamins",
          "minerals",
          "powder",
          "milk",
          "mix",
          "strength",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-2-1.png",
        link: "https://amzn.to/3T2i1iZ",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-4-1.png",
        link: "https://amzn.to/49GQd9u",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-6-1.png",
        link: "https://amzn.to/49lAfSa",
      },
    ],
  },
  {
    week: 16,
    slides: [
      {
        keywords: [
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "dress",
          "clothes",
          "cloth",
          "pure",
          "dresses",
          "summar",
          "tshirts",
          "t-shirt",
          "tops",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uFXXcQ",
      },
      {
        keywords: [
          "diet",
          "nutrition",
          "supplements",
          "protein",
          "vitamins",
          "minerals",
          "powder",
          "milk",
          "mix",
          "strength",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-2-1.png",
        link: "https://amzn.to/3T2i1iZ",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-4-1.png",
        link: "https://amzn.to/49GQd9u",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-6-1.png",
        link: "https://amzn.to/49lAfSa",
      },
    ],
  },
  {
    week: 17,
    slides: [
      {
        keywords: [
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "dress",
          "clothes",
          "cloth",
          "pure",
          "dresses",
          "summar",
          "tshirts",
          "t-shirt",
          "tops",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uFXXcQ",
      },
      {
        keywords: [
          "diet",
          "nutrition",
          "supplements",
          "protein",
          "vitamins",
          "minerals",
          "powder",
          "milk",
          "mix",
          "strength",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-2-1.png",
        link: "https://amzn.to/3T2i1iZ",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-4-1.png",
        link: "https://amzn.to/49GQd9u",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-6-1.png",
        link: "https://amzn.to/49lAfSa",
      },
    ],
  },
  {
    week: 18,
    slides: [
      {
        keywords: [
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "dress",
          "clothes",
          "cloth",
          "pure",
          "dresses",
          "summar",
          "tshirts",
          "t-shirt",
          "tops",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uFXXcQ",
      },
      {
        keywords: [
          "diet",
          "nutrition",
          "supplements",
          "protein",
          "vitamins",
          "minerals",
          "powder",
          "milk",
          "mix",
          "strength",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-2-1.png",
        link: "https://amzn.to/3T2i1iZ",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-4-1.png",
        link: "https://amzn.to/49GQd9u",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-6-1.png",
        link: "https://amzn.to/49lAfSa",
      },
    ],
  },
  {
    week: 19,
    slides: [
      {
        keywords: [
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "dress",
          "clothes",
          "cloth",
          "pure",
          "dresses",
          "summar",
          "tshirts",
          "t-shirt",
          "tops",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uFXXcQ",
      },
      {
        keywords: [
          "diet",
          "nutrition",
          "supplements",
          "protein",
          "vitamins",
          "minerals",
          "powder",
          "milk",
          "mix",
          "strength",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-2-1.png",
        link: "https://amzn.to/3T2i1iZ",
      },
      {
        keywords: ["massage", "oil", "body", "care", "relax"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-4-1.png",
        link: "https://amzn.to/49GQd9u",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Seasons-Hottest-Deals-6-1.png",
        link: "https://amzn.to/49lAfSa",
      },
    ],
  },
  {
    week: 20,
    slides: [
      {
        keywords: [
          "baby",
          "bedding",
          "nursery",
          "crib",
          "blankets",
          "decor",
          "room",
          "furniture",
          "crib",
          "mattress",
          "accessories",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-7-1.png",
        link: "https://amzn.to/3uEVXSc",
      },
      {
        keywords: [
          "Cotton",
          "bedsheets",
          "bed",
          "linens",
          "sheets",
          "covers",
          "beddings",
          "pillowcases",
          "duvet",
          "spread",
          "sets",
          "fitted",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-8-2.png",
        link: "https://amzn.to/3UT7sQN",
      },
      {
        keywords: [
          "sunglasses",
          "shades",
          "eyewear",
          "sunnies",
          "frames",
          "polarized",
          "designer",
          "fashion",
          "uv",
          "protection",
          "sports",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-9-1.png",
        link: "https://amzn.to/49kI4ru",
      },
      {
        keywords: [
          "necklace",
          "giva",
          "silver",
          "pendants",
          "earrings",
          "pendant",
          "set",
          "nose pin",
          "nose ring",
          "makeup",
          "lipstick",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-10-1.png",
        link: "https://amzn.to/49miLVY",
      },
    ],
  },
  {
    week: 21,
    slides: [
      {
        keywords: [
          "baby",
          "bedding",
          "nursery",
          "crib",
          "blankets",
          "decor",
          "room",
          "furniture",
          "crib",
          "mattress",
          "accessories",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-7-1.png",
        link: "https://amzn.to/3uEVXSc",
      },
      {
        keywords: [
          "Cotton",
          "bedsheets",
          "bed",
          "linens",
          "sheets",
          "covers",
          "beddings",
          "pillowcases",
          "duvet",
          "spread",
          "sets",
          "fitted",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-8-2.png",
        link: "https://amzn.to/3UT7sQN",
      },
      {
        keywords: [
          "sunglasses",
          "shades",
          "eyewear",
          "sunnies",
          "frames",
          "polarized",
          "designer",
          "fashion",
          "uv",
          "protection",
          "sports",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-9-1.png",
        link: "https://amzn.to/49kI4ru",
      },
      {
        keywords: [
          "necklace",
          "giva",
          "silver",
          "pendants",
          "earrings",
          "pendant",
          "set",
          "nose pin",
          "nose ring",
          "makeup",
          "lipstick",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-10-1.png",
        link: "https://amzn.to/49miLVY",
      },
    ],
  },
  {
    week: 22,
    slides: [
      {
        keywords: [
          "baby",
          "bedding",
          "nursery",
          "crib",
          "blankets",
          "decor",
          "room",
          "furniture",
          "crib",
          "mattress",
          "accessories",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-7-1.png",
        link: "https://amzn.to/3uEVXSc",
      },
      {
        keywords: [
          "Cotton",
          "bedsheets",
          "bed",
          "linens",
          "sheets",
          "covers",
          "beddings",
          "pillowcases",
          "duvet",
          "spread",
          "sets",
          "fitted",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-8-2.png",
        link: "https://amzn.to/3UT7sQN",
      },
      {
        keywords: [
          "sunglasses",
          "shades",
          "eyewear",
          "sunnies",
          "frames",
          "polarized",
          "designer",
          "fashion",
          "uv",
          "protection",
          "sports",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-9-1.png",
        link: "https://amzn.to/49kI4ru",
      },
      {
        keywords: [
          "necklace",
          "giva",
          "silver",
          "pendants",
          "earrings",
          "pendant",
          "set",
          "nose pin",
          "nose ring",
          "makeup",
          "lipstick",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-10-1.png",
        link: "https://amzn.to/49miLVY",
      },
    ],
  },
  {
    week: 23,
    slides: [
      {
        keywords: [
          "baby",
          "bedding",
          "nursery",
          "crib",
          "blankets",
          "decor",
          "room",
          "furniture",
          "crib",
          "mattress",
          "accessories",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-7-1.png",
        link: "https://amzn.to/3uEVXSc",
      },
      {
        keywords: [
          "Cotton",
          "bedsheets",
          "bed",
          "linens",
          "sheets",
          "covers",
          "beddings",
          "pillowcases",
          "duvet",
          "spread",
          "sets",
          "fitted",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-8-2.png",
        link: "https://amzn.to/3UT7sQN",
      },
      {
        keywords: [
          "sunglasses",
          "shades",
          "eyewear",
          "sunnies",
          "frames",
          "polarized",
          "designer",
          "fashion",
          "uv",
          "protection",
          "sports",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-9-1.png",
        link: "https://amzn.to/49kI4ru",
      },
      {
        keywords: [
          "necklace",
          "giva",
          "silver",
          "pendants",
          "earrings",
          "pendant",
          "set",
          "nose pin",
          "nose ring",
          "makeup",
          "lipstick",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-10-1.png",
        link: "https://amzn.to/49miLVY",
      },
    ],
  },
  {
    week: 24,
    slides: [
      {
        keywords: [
          "baby",
          "bedding",
          "nursery",
          "crib",
          "blankets",
          "decor",
          "room",
          "furniture",
          "crib",
          "mattress",
          "accessories",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-7-1.png",
        link: "https://amzn.to/3uEVXSc",
      },
      {
        keywords: [
          "Cotton",
          "bedsheets",
          "bed",
          "linens",
          "sheets",
          "covers",
          "beddings",
          "pillowcases",
          "duvet",
          "spread",
          "sets",
          "fitted",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-8-2.png",
        link: "https://amzn.to/3UT7sQN",
      },
      {
        keywords: [
          "sunglasses",
          "shades",
          "eyewear",
          "sunnies",
          "frames",
          "polarized",
          "designer",
          "fashion",
          "uv",
          "protection",
          "sports",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-9-1.png",
        link: "https://amzn.to/49kI4ru",
      },
      {
        keywords: [
          "necklace",
          "giva",
          "silver",
          "pendants",
          "earrings",
          "pendant",
          "set",
          "nose pin",
          "nose ring",
          "makeup",
          "lipstick",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-10-1.png",
        link: "https://amzn.to/49miLVY",
      },
    ],
  },
  {
    week: 25,
    slides: [
      {
        keywords: [
          "baby",
          "bedding",
          "nursery",
          "crib",
          "blankets",
          "decor",
          "room",
          "furniture",
          "crib",
          "mattress",
          "accessories",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-7-1.png",
        link: "https://amzn.to/3uEVXSc",
      },
      {
        keywords: [
          "Cotton",
          "bedsheets",
          "bed",
          "linens",
          "sheets",
          "covers",
          "beddings",
          "pillowcases",
          "duvet",
          "spread",
          "sets",
          "fitted",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-8-2.png",
        link: "https://amzn.to/3UT7sQN",
      },
      {
        keywords: [
          "sunglasses",
          "shades",
          "eyewear",
          "sunnies",
          "frames",
          "polarized",
          "designer",
          "fashion",
          "uv",
          "protection",
          "sports",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-9-1.png",
        link: "https://amzn.to/49kI4ru",
      },
      {
        keywords: [
          "necklace",
          "giva",
          "silver",
          "pendants",
          "earrings",
          "pendant",
          "set",
          "nose pin",
          "nose ring",
          "makeup",
          "lipstick",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-10-1.png",
        link: "https://amzn.to/49miLVY",
      },
    ],
  },
  {
    week: 26,
    slides: [
      {
        keywords: [
          "baby",
          "bedding",
          "nursery",
          "crib",
          "blankets",
          "decor",
          "room",
          "furniture",
          "crib",
          "mattress",
          "accessories",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-7-1.png",
        link: "https://amzn.to/3uEVXSc",
      },
      {
        keywords: [
          "Cotton",
          "bedsheets",
          "bed",
          "linens",
          "sheets",
          "covers",
          "beddings",
          "pillowcases",
          "duvet",
          "spread",
          "sets",
          "fitted",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-8-2.png",
        link: "https://amzn.to/3UT7sQN",
      },
      {
        keywords: [
          "sunglasses",
          "shades",
          "eyewear",
          "sunnies",
          "frames",
          "polarized",
          "designer",
          "fashion",
          "uv",
          "protection",
          "sports",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-9-1.png",
        link: "https://amzn.to/49kI4ru",
      },
      {
        keywords: [
          "necklace",
          "giva",
          "silver",
          "pendants",
          "earrings",
          "pendant",
          "set",
          "nose pin",
          "nose ring",
          "makeup",
          "lipstick",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Dhamaal-Discounts-T2a-10-1.png",
        link: "https://amzn.to/49miLVY",
      },
    ],
  },
  {
    week: 27,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-10-min-1.png",
        link: "https://amzn.to/42PCkn2",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
          "dresses",
          "pants",
          "leggings",
          "panty",
          "bras",
          "bra",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-11-min.png",
        link: "https://amzn.to/49FdTL6",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-13-min.png",
        link: "https://amzn.to/3uLnFwx",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-14-min.png",
        link: "https://amzn.to/3Ia1wet",
      },
    ],
  },
  {
    week: 28,
    slides: [
      {
        keywords: [
          "health",
          "healthy",
          "ghee",
          "butter",
          "gir",
          "cow",
          "laddoo",
          "snack",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uMmdds",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-2.png",
        link: "https://amzn.to/49umm46",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
          "massage",
          "oil",
          "body",
          "care",
          "relax",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-3-1.png",
        link: "https://amzn.to/3TeW1lc",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-4.png",
        link: "https://amzn.to/3uN69Ii",
      },
    ],
  },
  {
    week: 29,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-10-min-1.png",
        link: "https://amzn.to/42PCkn2",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
          "dresses",
          "pants",
          "leggings",
          "panty",
          "bras",
          "bra",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-11-min.png",
        link: "https://amzn.to/49FdTL6",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-13-min.png",
        link: "https://amzn.to/3uLnFwx",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-14-min.png",
        link: "https://amzn.to/3Ia1wet",
      },
    ],
  },
  {
    week: 30,
    slides: [
      {
        keywords: [
          "health",
          "healthy",
          "ghee",
          "butter",
          "gir",
          "cow",
          "laddoo",
          "snack",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uMmdds",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-2.png",
        link: "https://amzn.to/49umm46",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
          "massage",
          "oil",
          "body",
          "care",
          "relax",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-3-1.png",
        link: "https://amzn.to/3TeW1lc",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-4.png",
        link: "https://amzn.to/3uN69Ii",
      },
    ],
  },
  {
    week: 31,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-10-min-1.png",
        link: "https://amzn.to/42PCkn2",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
          "dresses",
          "pants",
          "leggings",
          "panty",
          "bras",
          "bra",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-11-min.png",
        link: "https://amzn.to/49FdTL6",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-13-min.png",
        link: "https://amzn.to/3uLnFwx",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-14-min.png",
        link: "https://amzn.to/3Ia1wet",
      },
    ],
  },
  {
    week: 32,
    slides: [
      {
        keywords: [
          "health",
          "healthy",
          "ghee",
          "butter",
          "gir",
          "cow",
          "laddoo",
          "snack",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uMmdds",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-2.png",
        link: "https://amzn.to/49umm46",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
          "massage",
          "oil",
          "body",
          "care",
          "relax",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-3-1.png",
        link: "https://amzn.to/3TeW1lc",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-4.png",
        link: "https://amzn.to/3uN69Ii",
      },
    ],
  },
  {
    week: 33,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-10-min-1.png",
        link: "https://amzn.to/42PCkn2",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
          "dresses",
          "pants",
          "leggings",
          "panty",
          "bras",
          "bra",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-11-min.png",
        link: "https://amzn.to/49FdTL6",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-13-min.png",
        link: "https://amzn.to/3uLnFwx",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-14-min.png",
        link: "https://amzn.to/3Ia1wet",
      },
    ],
  },
  {
    week: 34,
    slides: [
      {
        keywords: [
          "health",
          "healthy",
          "ghee",
          "butter",
          "gir",
          "cow",
          "laddoo",
          "snack",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uMmdds",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-2.png",
        link: "https://amzn.to/49umm46",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
          "massage",
          "oil",
          "body",
          "care",
          "relax",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-3-1.png",
        link: "https://amzn.to/3TeW1lc",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-4.png",
        link: "https://amzn.to/3uN69Ii",
      },
    ],
  },
  {
    week: 35,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-10-min-1.png",
        link: "https://amzn.to/42PCkn2",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
          "dresses",
          "pants",
          "leggings",
          "panty",
          "bras",
          "bra",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-11-min.png",
        link: "https://amzn.to/49FdTL6",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-13-min.png",
        link: "https://amzn.to/3uLnFwx",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-14-min.png",
        link: "https://amzn.to/3Ia1wet",
      },
    ],
  },
  {
    week: 36,
    slides: [
      {
        keywords: [
          "health",
          "healthy",
          "ghee",
          "butter",
          "gir",
          "cow",
          "laddoo",
          "snack",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uMmdds",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-2.png",
        link: "https://amzn.to/49umm46",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
          "massage",
          "oil",
          "body",
          "care",
          "relax",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-3-1.png",
        link: "https://amzn.to/3TeW1lc",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-4.png",
        link: "https://amzn.to/3uN69Ii",
      },
    ],
  },
  {
    week: 37,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "body",
          "oil",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-10-min-1.png",
        link: "https://amzn.to/42PCkn2",
      },
      {
        keywords: [
          "indo",
          "western",
          "dress",
          "gown",
          "indian",
          "anarkali",
          "libas",
          "maxi",
          "party",
          "co",
          "ord",
          "set",
          "jump",
          "suit",
          "jumpsuit",
          "party",
          "wear",
          "dress",
          "western",
          "tops",
          "jeans",
          "ethnic",
          "cotton",
          "suit",
          "salwar",
          "clothes",
          "cloth",
          "dresses",
          "pants",
          "leggings",
          "panty",
          "bras",
          "bra",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-11-min.png",
        link: "https://amzn.to/49FdTL6",
      },
      {
        keywords: [
          "Indian",
          "Clothing",
          "Saree",
          "Blouses",
          "Churidar",
          "Salwar",
          "Bottoms",
          "Chunnis",
          "Dupattas",
          "Dresses",
          "Unstitched",
          "Ethnic",
          "Kurtas",
          "Kurtis",
          "Lehenga",
          "Cholis",
          "Suits",
          "feeding",
          "gown",
          "maternity",
          "kurti",
          "nighty",
          "night",
          "dress",
          "sleep",
          "wear",
          "pyjama",
          "top",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-13-min.png",
        link: "https://amzn.to/3uLnFwx",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Template-4-14-min.png",
        link: "https://amzn.to/3Ia1wet",
      },
    ],
  },
  {
    week: 38,
    slides: [
      {
        keywords: [
          "health",
          "healthy",
          "ghee",
          "butter",
          "gir",
          "cow",
          "laddoo",
          "snack",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uMmdds",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-2.png",
        link: "https://amzn.to/49umm46",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
          "massage",
          "oil",
          "body",
          "care",
          "relax",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-3-1.png",
        link: "https://amzn.to/3TeW1lc",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-4.png",
        link: "https://amzn.to/3uN69Ii",
      },
    ],
  },
  {
    week: 39,
    slides: [
      {
        keywords: [
          "health",
          "healthy",
          "ghee",
          "butter",
          "gir",
          "cow",
          "laddoo",
          "snack",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uMmdds",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-2.png",
        link: "https://amzn.to/49umm46",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
          "massage",
          "oil",
          "body",
          "care",
          "relax",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-3-1.png",
        link: "https://amzn.to/3TeW1lc",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-4.png",
        link: "https://amzn.to/3uN69Ii",
      },
    ],
  },
  {
    week: 40,
    slides: [
      {
        keywords: [
          "health",
          "healthy",
          "ghee",
          "butter",
          "gir",
          "cow",
          "laddoo",
          "snack",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-1-1.png",
        link: "https://amzn.to/3uMmdds",
      },
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-2.png",
        link: "https://amzn.to/49umm46",
      },
      {
        keywords: [
          "cloths",
          "cloth",
          "baby",
          "100%",
          "pure",
          "cotton",
          "zero",
          "size",
          "0",
          "6",
          "0-6",
          "months",
          "year",
          "massage",
          "oil",
          "body",
          "care",
          "relax",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-3-1.png",
        link: "https://amzn.to/3TeW1lc",
      },
      {
        keywords: [
          "pillow",
          "wedge",
          "maternity",
          "support",
          "belly",
          "support",
          "back",
          "support",
          "knee",
          "support",
          "legs",
          "support",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Template-4-4.png",
        link: "https://amzn.to/3uN69Ii",
      },
    ],
  },
];

export const pregnancyReadImages = [
  {
    week: 4,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-4-1.png",
        link: "https://amzn.to/48gjarz",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-5-1.png",
        link: "https://amzn.to/3wmlhg3",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-6-1.png",
        link: "https://amzn.to/3OH50Zu",
      },
    ],
  },
  {
    week: 5,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-8-1.png",
        link: "https://amzn.to/3unTyeB",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-9-1-1-1.png",
        link: "https://amzn.to/3uz3ime",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-10-1.png",
        link: "https://amzn.to/3uwVLUZ",
      },
    ],
  },
  {
    week: 6,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-4-1.png",
        link: "https://amzn.to/48gjarz",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-5-1.png",
        link: "https://amzn.to/3wmlhg3",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-6-1.png",
        link: "https://amzn.to/3OH50Zu",
      },
    ],
  },
  {
    week: 7,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-8-1.png",
        link: "https://amzn.to/3unTyeB",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-9-1-1-1.png",
        link: "https://amzn.to/3uz3ime",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-10-1.png",
        link: "https://amzn.to/3uwVLUZ",
      },
    ],
  },
  {
    week: 8,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-4-1.png",
        link: "https://amzn.to/48gjarz",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-5-1.png",
        link: "https://amzn.to/3wmlhg3",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-6-1.png",
        link: "https://amzn.to/3OH50Zu",
      },
    ],
  },
  {
    week: 9,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-8-1.png",
        link: "https://amzn.to/3unTyeB",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-9-1-1-1.png",
        link: "https://amzn.to/3uz3ime",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-10-1.png",
        link: "https://amzn.to/3uwVLUZ",
      },
    ],
  },
  {
    week: 10,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-4-1.png",
        link: "https://amzn.to/48gjarz",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-5-1.png",
        link: "https://amzn.to/3wmlhg3",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-6-1.png",
        link: "https://amzn.to/3OH50Zu",
      },
    ],
  },
  {
    week: 11,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-8-1.png",
        link: "https://amzn.to/3unTyeB",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-9-1-1-1.png",
        link: "https://amzn.to/3uz3ime",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-10-1.png",
        link: "https://amzn.to/3uwVLUZ",
      },
    ],
  },
  {
    week: 12,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-4-1.png",
        link: "https://amzn.to/48gjarz",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-5-1.png",
        link: "https://amzn.to/3wmlhg3",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-6-1.png",
        link: "https://amzn.to/3OH50Zu",
      },
    ],
  },
  {
    week: 13,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-14-1.png",
        link: "https://amzn.to/3uukzwZ",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-10-1.png",
        link: "https://amzn.to/3ULGOZU",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-15-1.png",
        link: "https://amzn.to/3uuksl3",
      },
    ],
  },
  {
    week: 14,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-14-1.png",
        link: "https://amzn.to/3uukzwZ",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-10-1.png",
        link: "https://amzn.to/3ULGOZU",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-15-1.png",
        link: "https://amzn.to/3uuksl3",
      },
    ],
  },
  {
    week: 15,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-14-1.png",
        link: "https://amzn.to/3uukzwZ",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-10-1.png",
        link: "https://amzn.to/3ULGOZU",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-15-1.png",
        link: "https://amzn.to/3uuksl3",
      },
    ],
  },
  {
    week: 16,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-14-1.png",
        link: "https://amzn.to/3uukzwZ",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-10-1.png",
        link: "https://amzn.to/3ULGOZU",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-15-1.png",
        link: "https://amzn.to/3uuksl3",
      },
    ],
  },
  {
    week: 17,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-14-1.png",
        link: "https://amzn.to/3uukzwZ",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-10-1.png",
        link: "https://amzn.to/3ULGOZU",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-15-1.png",
        link: "https://amzn.to/3uuksl3",
      },
    ],
  },
  {
    week: 18,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-14-1.png",
        link: "https://amzn.to/3uukzwZ",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-10-1.png",
        link: "https://amzn.to/3ULGOZU",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-15-1.png",
        link: "https://amzn.to/3uuksl3",
      },
    ],
  },
  {
    week: 19,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-14-1.png",
        link: "https://amzn.to/3uukzwZ",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-10-1.png",
        link: "https://amzn.to/3ULGOZU",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-15-1.png",
        link: "https://amzn.to/3uuksl3",
      },
    ],
  },
  {
    week: 20,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-19-1.png",
        link: "https://amzn.to/3I8V8nE",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-20-1-1-1-1-1-1.png",
        link: "https://amzn.to/3I9ctx0",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-21-1.png",
        link: "https://amzn.to/3uIKK36",
      },
    ],
  },
  {
    week: 21,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-19-1.png",
        link: "https://amzn.to/3I8V8nE",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-20-1-1-1-1-1-1.png",
        link: "https://amzn.to/3I9ctx0",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-21-1.png",
        link: "https://amzn.to/3uIKK36",
      },
    ],
  },
  {
    week: 22,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-19-1.png",
        link: "https://amzn.to/3I8V8nE",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-20-1-1-1-1-1-1.png",
        link: "https://amzn.to/3I9ctx0",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-21-1.png",
        link: "https://amzn.to/3uIKK36",
      },
    ],
  },
  {
    week: 23,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-19-1.png",
        link: "https://amzn.to/3I8V8nE",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-20-1-1-1-1-1-1.png",
        link: "https://amzn.to/3I9ctx0",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-21-1.png",
        link: "https://amzn.to/3uIKK36",
      },
    ],
  },
  {
    week: 24,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-19-1.png",
        link: "https://amzn.to/3I8V8nE",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-20-1-1-1-1-1-1.png",
        link: "https://amzn.to/3I9ctx0",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-21-1.png",
        link: "https://amzn.to/3uIKK36",
      },
    ],
  },
  {
    week: 25,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-19-1.png",
        link: "https://amzn.to/3I8V8nE",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-20-1-1-1-1-1-1.png",
        link: "https://amzn.to/3I9ctx0",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-21-1.png",
        link: "https://amzn.to/3uIKK36",
      },
    ],
  },
  {
    week: 26,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-19-1.png",
        link: "https://amzn.to/3I8V8nE",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-20-1-1-1-1-1-1.png",
        link: "https://amzn.to/3I9ctx0",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-21-1.png",
        link: "https://amzn.to/3uIKK36",
      },
    ],
  },
  {
    week: 27,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-26-min.png",
        link: "https://amzn.to/3Ta1MAD",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-27-min.png",
        link: "https://amzn.to/3SJI11c",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-28-min.png",
        link: "https://amzn.to/3OUWO8h",
      },
    ],
  },
  {
    week: 28,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-5-1.png",
        link: "https://amzn.to/3wmlhg3",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-31-1.png",
        link: "https://amzn.to/3OXmCkb",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-32-1.png",
        link: "https://amzn.to/42WcFcd",
      },
    ],
  },
  {
    week: 29,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-26-min.png",
        link: "https://amzn.to/3Ta1MAD",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-27-min.png",
        link: "https://amzn.to/3SJI11c",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-28-min.png",
        link: "https://amzn.to/3OUWO8h",
      },
    ],
  },
  {
    week: 30,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-5-1.png",
        link: "https://amzn.to/3wmlhg3",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-31-1.png",
        link: "https://amzn.to/3OXmCkb",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-32-1.png",
        link: "https://amzn.to/42WcFcd",
      },
    ],
  },
  {
    week: 31,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-26-min.png",
        link: "https://amzn.to/3Ta1MAD",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-27-min.png",
        link: "https://amzn.to/3SJI11c",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-28-min.png",
        link: "https://amzn.to/3OUWO8h",
      },
    ],
  },
  {
    week: 32,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-5-1.png",
        link: "https://amzn.to/3wmlhg3",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-31-1.png",
        link: "https://amzn.to/3OXmCkb",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-32-1.png",
        link: "https://amzn.to/42WcFcd",
      },
    ],
  },
  {
    week: 33,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-26-min.png",
        link: "https://amzn.to/3Ta1MAD",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-27-min.png",
        link: "https://amzn.to/3SJI11c",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-28-min.png",
        link: "https://amzn.to/3OUWO8h",
      },
    ],
  },
  {
    week: 34,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-5-1.png",
        link: "https://amzn.to/3wmlhg3",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-31-1.png",
        link: "https://amzn.to/3OXmCkb",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-32-1.png",
        link: "https://amzn.to/42WcFcd",
      },
    ],
  },
  {
    week: 35,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-26-min.png",
        link: "https://amzn.to/3Ta1MAD",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-27-min.png",
        link: "https://amzn.to/3SJI11c",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-28-min.png",
        link: "https://amzn.to/3OUWO8h",
      },
    ],
  },
  {
    week: 36,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-5-1.png",
        link: "https://amzn.to/3wmlhg3",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-31-1.png",
        link: "https://amzn.to/3OXmCkb",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-32-1.png",
        link: "https://amzn.to/42WcFcd",
      },
    ],
  },
  {
    week: 37,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-26-min.png",
        link: "https://amzn.to/3Ta1MAD",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-27-min.png",
        link: "https://amzn.to/3SJI11c",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
          "breast",
          "feeding",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-28-min.png",
        link: "https://amzn.to/3OUWO8h",
      },
    ],
  },
  {
    week: 38,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-5-1.png",
        link: "https://amzn.to/3wmlhg3",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-31-1.png",
        link: "https://amzn.to/3OXmCkb",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-32-1.png",
        link: "https://amzn.to/42WcFcd",
      },
    ],
  },
  {
    week: 39,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-5-1.png",
        link: "https://amzn.to/3wmlhg3",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-31-1.png",
        link: "https://amzn.to/3OXmCkb",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-32-1.png",
        link: "https://amzn.to/42WcFcd",
      },
    ],
  },
  {
    week: 40,
    slides: [
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-5-1.png",
        link: "https://amzn.to/3wmlhg3",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-31-1.png",
        link: "https://amzn.to/3OXmCkb",
      },
      {
        keywords: [
          "garbha",
          "sanskar",
          "magical",
          "beginnings",
          "pregnancy",
          "childbirth",
          "book",
          "nutrition",
          "health",
          "healthy",
          "food",
          "guide",
          "welness",
          "salads",
          "recipes",
          "what",
          "to",
          "eat",
          "baby",
          "care",
          "books",
          "reading",
          "read",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-32-1.png",
        link: "https://amzn.to/42WcFcd",
      },
    ],
  },
];

export const topBrandsImages = [
  {
    week: 4,
    slides: [
      {
        keywords: [
          "body",
          "lotion",
          "stretch",
          "mark",
          "cream",
          "oil",
          "massage",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-2-1.png",
        link: "https://amzn.to/497cM7n",
      },
      {
        keywords: [
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "snack",
          "laddu",
          "cookies",
          "millet",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-3-1.png",
        link: "https://amzn.to/3wg4QC3",
      },
      {
        keywords: [
          "winter",
          "warmer",
          "thermal",
          "sweater",
          "zipper",
          "hoodie",
          "sweatshirt",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-7-1.png",
        link: "https://amzn.to/3SRyvdx",
      },
    ],
  },
  {
    week: 5,
    slides: [
      {
        keywords: [
          "wellness",
          "fat",
          "burner",
          "iron",
          "folic",
          "acid",
          "supplement",
          "constipation",
          "women's",
          "daily",
          "detox",
          "muscle",
          "joint",
          "pain",
          "fast",
          "relief",
          "ointment",
          "immunity",
          "amla",
          "collagen",
          "builder",
          "anti",
          "acne",
          "blood",
          "purifier",
          "pigmentation",
          "neem",
          "calcium",
          "vitamin",
          "d3",
          "spinach",
          "drops",
          "sleep",
          "support",
          "stress",
          "thyroid",
          "liver",
          "ayurvedic",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-11-1-1.png",
        link: "https://amzn.to/3T0uiog",
      },
      {
        keywords: [
          "laddu",
          "chocolate",
          "hazelnut",
          "diet",
          "nuts",
          "cravings",
          "fetus",
          "development",
          "ragi",
          "essential",
          "fatty",
          "acids",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-12-1-1.png",
        link: "https://amzn.to/48dOXJO",
      },
      {
        keywords: [
          "laddu",
          "chocolate",
          "hazelnut",
          "diet",
          "nuts",
          "cravings",
          "fetus",
          "development",
          "ragi",
          "essential",
          "fatty",
          "acids",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-13-1.png",
        link: "https://amzn.to/48atwcw",
      },
    ],
  },
  {
    week: 6,
    slides: [
      {
        keywords: [
          "body",
          "lotion",
          "stretch",
          "mark",
          "cream",
          "oil",
          "massage",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-2-1.png",
        link: "https://amzn.to/497cM7n",
      },
      {
        keywords: [
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "snack",
          "laddu",
          "cookies",
          "millet",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-3-1.png",
        link: "https://amzn.to/3wg4QC3",
      },
      {
        keywords: [
          "winter",
          "warmer",
          "thermal",
          "sweater",
          "zipper",
          "hoodie",
          "sweatshirt",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-7-1.png",
        link: "https://amzn.to/3SRyvdx",
      },
    ],
  },
  {
    week: 7,
    slides: [
      {
        keywords: [
          "wellness",
          "fat",
          "burner",
          "iron",
          "folic",
          "acid",
          "supplement",
          "constipation",
          "women's",
          "daily",
          "detox",
          "muscle",
          "joint",
          "pain",
          "fast",
          "relief",
          "ointment",
          "immunity",
          "amla",
          "collagen",
          "builder",
          "anti",
          "acne",
          "blood",
          "purifier",
          "pigmentation",
          "neem",
          "calcium",
          "vitamin",
          "d3",
          "spinach",
          "drops",
          "sleep",
          "support",
          "stress",
          "thyroid",
          "liver",
          "ayurvedic",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-11-1-1.png",
        link: "https://amzn.to/3T0uiog",
      },
      {
        keywords: [
          "laddu",
          "chocolate",
          "hazelnut",
          "diet",
          "nuts",
          "cravings",
          "fetus",
          "development",
          "ragi",
          "essential",
          "fatty",
          "acids",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-12-1-1.png",
        link: "https://amzn.to/48dOXJO",
      },
      {
        keywords: [
          "laddu",
          "chocolate",
          "hazelnut",
          "diet",
          "nuts",
          "cravings",
          "fetus",
          "development",
          "ragi",
          "essential",
          "fatty",
          "acids",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-13-1.png",
        link: "https://amzn.to/48atwcw",
      },
    ],
  },
  {
    week: 8,
    slides: [
      {
        keywords: [
          "body",
          "lotion",
          "stretch",
          "mark",
          "cream",
          "oil",
          "massage",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-2-1.png",
        link: "https://amzn.to/497cM7n",
      },
      {
        keywords: [
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "snack",
          "laddu",
          "cookies",
          "millet",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-3-1.png",
        link: "https://amzn.to/3wg4QC3",
      },
      {
        keywords: [
          "winter",
          "warmer",
          "thermal",
          "sweater",
          "zipper",
          "hoodie",
          "sweatshirt",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-7-1.png",
        link: "https://amzn.to/3SRyvdx",
      },
    ],
  },
  {
    week: 9,
    slides: [
      {
        keywords: [
          "wellness",
          "fat",
          "burner",
          "iron",
          "folic",
          "acid",
          "supplement",
          "constipation",
          "women's",
          "daily",
          "detox",
          "muscle",
          "joint",
          "pain",
          "fast",
          "relief",
          "ointment",
          "immunity",
          "amla",
          "collagen",
          "builder",
          "anti",
          "acne",
          "blood",
          "purifier",
          "pigmentation",
          "neem",
          "calcium",
          "vitamin",
          "d3",
          "spinach",
          "drops",
          "sleep",
          "support",
          "stress",
          "thyroid",
          "liver",
          "ayurvedic",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-11-1-1.png",
        link: "https://amzn.to/3T0uiog",
      },
      {
        keywords: [
          "laddu",
          "chocolate",
          "hazelnut",
          "diet",
          "nuts",
          "cravings",
          "fetus",
          "development",
          "ragi",
          "essential",
          "fatty",
          "acids",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-12-1-1.png",
        link: "https://amzn.to/48dOXJO",
      },
      {
        keywords: [
          "laddu",
          "chocolate",
          "hazelnut",
          "diet",
          "nuts",
          "cravings",
          "fetus",
          "development",
          "ragi",
          "essential",
          "fatty",
          "acids",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-13-1.png",
        link: "https://amzn.to/48atwcw",
      },
    ],
  },
  {
    week: 10,
    slides: [
      {
        keywords: [
          "body",
          "lotion",
          "stretch",
          "mark",
          "cream",
          "oil",
          "massage",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-2-1.png",
        link: "https://amzn.to/497cM7n",
      },
      {
        keywords: [
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "snack",
          "laddu",
          "cookies",
          "millet",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-3-1.png",
        link: "https://amzn.to/3wg4QC3",
      },
      {
        keywords: [
          "winter",
          "warmer",
          "thermal",
          "sweater",
          "zipper",
          "hoodie",
          "sweatshirt",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-7-1.png",
        link: "https://amzn.to/3SRyvdx",
      },
    ],
  },
  {
    week: 11,
    slides: [
      {
        keywords: [
          "wellness",
          "fat",
          "burner",
          "iron",
          "folic",
          "acid",
          "supplement",
          "constipation",
          "women's",
          "daily",
          "detox",
          "muscle",
          "joint",
          "pain",
          "fast",
          "relief",
          "ointment",
          "immunity",
          "amla",
          "collagen",
          "builder",
          "anti",
          "acne",
          "blood",
          "purifier",
          "pigmentation",
          "neem",
          "calcium",
          "vitamin",
          "d3",
          "spinach",
          "drops",
          "sleep",
          "support",
          "stress",
          "thyroid",
          "liver",
          "ayurvedic",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-11-1-1.png",
        link: "https://amzn.to/3T0uiog",
      },
      {
        keywords: [
          "laddu",
          "chocolate",
          "hazelnut",
          "diet",
          "nuts",
          "cravings",
          "fetus",
          "development",
          "ragi",
          "essential",
          "fatty",
          "acids",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-12-1-1.png",
        link: "https://amzn.to/48dOXJO",
      },
      {
        keywords: [
          "laddu",
          "chocolate",
          "hazelnut",
          "diet",
          "nuts",
          "cravings",
          "fetus",
          "development",
          "ragi",
          "essential",
          "fatty",
          "acids",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-13-1.png",
        link: "https://amzn.to/48atwcw",
      },
    ],
  },
  {
    week: 12,
    slides: [
      {
        keywords: [
          "body",
          "lotion",
          "stretch",
          "mark",
          "cream",
          "oil",
          "massage",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-2-1.png",
        link: "https://amzn.to/497cM7n",
      },
      {
        keywords: [
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "snack",
          "laddu",
          "cookies",
          "millet",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-3-1.png",
        link: "https://amzn.to/3wg4QC3",
      },
      {
        keywords: [
          "winter",
          "warmer",
          "thermal",
          "sweater",
          "zipper",
          "hoodie",
          "sweatshirt",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-7-1.png",
        link: "https://amzn.to/3SRyvdx",
      },
    ],
  },
  {
    week: 13,
    slides: [
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-16-1.png",
        link: "https://amzn.to/3uwmyRp",
      },
      {
        keywords: [
          "herbal",
          "teas",
          "pickles",
          "spices",
          "super",
          "seeds",
          "immunity",
          "personal",
          "care",
          "honey",
          "tea",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-17-1-1-1-1.png",
        link: "https://amzn.to/3wiDGKP",
      },
      {
        keywords: [
          "detergent",
          "wash",
          "clean",
          "dry",
          "washing",
          "machine",
          "nirama",
          "powder",
          "eco",
          "friendly",
          "not",
          "no",
          "harmful",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-18-1.png",
        link: "https://amzn.to/3T0OQN2",
      },
    ],
  },
  {
    week: 14,
    slides: [
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-16-1.png",
        link: "https://amzn.to/3uwmyRp",
      },
      {
        keywords: [
          "herbal",
          "teas",
          "pickles",
          "spices",
          "super",
          "seeds",
          "immunity",
          "personal",
          "care",
          "honey",
          "tea",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-17-1-1-1-1.png",
        link: "https://amzn.to/3wiDGKP",
      },
      {
        keywords: [
          "detergent",
          "wash",
          "clean",
          "dry",
          "washing",
          "machine",
          "nirama",
          "powder",
          "eco",
          "friendly",
          "not",
          "no",
          "harmful",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-18-1.png",
        link: "https://amzn.to/3T0OQN2",
      },
    ],
  },
  {
    week: 15,
    slides: [
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-16-1.png",
        link: "https://amzn.to/3uwmyRp",
      },
      {
        keywords: [
          "herbal",
          "teas",
          "pickles",
          "spices",
          "super",
          "seeds",
          "immunity",
          "personal",
          "care",
          "honey",
          "tea",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-17-1-1-1-1.png",
        link: "https://amzn.to/3wiDGKP",
      },
      {
        keywords: [
          "detergent",
          "wash",
          "clean",
          "dry",
          "washing",
          "machine",
          "nirama",
          "powder",
          "eco",
          "friendly",
          "not",
          "no",
          "harmful",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-18-1.png",
        link: "https://amzn.to/3T0OQN2",
      },
    ],
  },
  {
    week: 16,
    slides: [
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-16-1.png",
        link: "https://amzn.to/3uwmyRp",
      },
      {
        keywords: [
          "herbal",
          "teas",
          "pickles",
          "spices",
          "super",
          "seeds",
          "immunity",
          "personal",
          "care",
          "honey",
          "tea",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-17-1-1-1-1.png",
        link: "https://amzn.to/3wiDGKP",
      },
      {
        keywords: [
          "detergent",
          "wash",
          "clean",
          "dry",
          "washing",
          "machine",
          "nirama",
          "powder",
          "eco",
          "friendly",
          "not",
          "no",
          "harmful",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-18-1.png",
        link: "https://amzn.to/3T0OQN2",
      },
    ],
  },
  {
    week: 17,
    slides: [
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-16-1.png",
        link: "https://amzn.to/3uwmyRp",
      },
      {
        keywords: [
          "herbal",
          "teas",
          "pickles",
          "spices",
          "super",
          "seeds",
          "immunity",
          "personal",
          "care",
          "honey",
          "tea",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-17-1-1-1-1.png",
        link: "https://amzn.to/3wiDGKP",
      },
      {
        keywords: [
          "detergent",
          "wash",
          "clean",
          "dry",
          "washing",
          "machine",
          "nirama",
          "powder",
          "eco",
          "friendly",
          "not",
          "no",
          "harmful",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-18-1.png",
        link: "https://amzn.to/3T0OQN2",
      },
    ],
  },
  {
    week: 18,
    slides: [
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-16-1.png",
        link: "https://amzn.to/3uwmyRp",
      },
      {
        keywords: [
          "herbal",
          "teas",
          "pickles",
          "spices",
          "super",
          "seeds",
          "immunity",
          "personal",
          "care",
          "honey",
          "tea",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-17-1-1-1-1.png",
        link: "https://amzn.to/3wiDGKP",
      },
      {
        keywords: [
          "detergent",
          "wash",
          "clean",
          "dry",
          "washing",
          "machine",
          "nirama",
          "powder",
          "eco",
          "friendly",
          "not",
          "no",
          "harmful",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-18-1.png",
        link: "https://amzn.to/3T0OQN2",
      },
    ],
  },
  {
    week: 19,
    slides: [
      {
        keywords: [
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-16-1.png",
        link: "https://amzn.to/3uwmyRp",
      },
      {
        keywords: [
          "herbal",
          "teas",
          "pickles",
          "spices",
          "super",
          "seeds",
          "immunity",
          "personal",
          "care",
          "honey",
          "tea",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-17-1-1-1-1.png",
        link: "https://amzn.to/3wiDGKP",
      },
      {
        keywords: [
          "detergent",
          "wash",
          "clean",
          "dry",
          "washing",
          "machine",
          "nirama",
          "powder",
          "eco",
          "friendly",
          "not",
          "no",
          "harmful",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-18-1.png",
        link: "https://amzn.to/3T0OQN2",
      },
    ],
  },
  {
    week: 20,
    slides: [
      {
        keywords: [
          "millet",
          "noodles",
          "pasta",
          "cereals",
          "breakfast",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-22-1.png",
        link: "https://amzn.to/3wlqULv",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "iron",
          "zinc",
          "vitamin",
          "vitals",
          "B12",
          "D",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-23.png",
        link: "https://amzn.to/3I5yTiw",
      },
      {
        keywords: [
          "baby",
          "wash",
          "soap",
          "shampoo",
          "cloths",
          "infant",
          "newborn",
          "cotton",
          "set",
          "100%",
          "pure",
          "himalaya",
          "mumma",
          "earth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-24-1-1-1-1.png",
        link: "https://amzn.to/3UOWOdM",
      },
    ],
  },
  {
    week: 21,
    slides: [
      {
        keywords: [
          "millet",
          "noodles",
          "pasta",
          "cereals",
          "breakfast",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-22-1.png",
        link: "https://amzn.to/3wlqULv",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "iron",
          "zinc",
          "vitamin",
          "vitals",
          "B12",
          "D",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-23.png",
        link: "https://amzn.to/3I5yTiw",
      },
      {
        keywords: [
          "baby",
          "wash",
          "soap",
          "shampoo",
          "cloths",
          "infant",
          "newborn",
          "cotton",
          "set",
          "100%",
          "pure",
          "himalaya",
          "mumma",
          "earth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-24-1-1-1-1.png",
        link: "https://amzn.to/3UOWOdM",
      },
    ],
  },
  {
    week: 22,
    slides: [
      {
        keywords: [
          "millet",
          "noodles",
          "pasta",
          "cereals",
          "breakfast",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-22-1.png",
        link: "https://amzn.to/3wlqULv",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "iron",
          "zinc",
          "vitamin",
          "vitals",
          "B12",
          "D",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-23.png",
        link: "https://amzn.to/3I5yTiw",
      },
      {
        keywords: [
          "baby",
          "wash",
          "soap",
          "shampoo",
          "cloths",
          "infant",
          "newborn",
          "cotton",
          "set",
          "100%",
          "pure",
          "himalaya",
          "mumma",
          "earth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-24-1-1-1-1.png",
        link: "https://amzn.to/3UOWOdM",
      },
    ],
  },
  {
    week: 23,
    slides: [
      {
        keywords: [
          "millet",
          "noodles",
          "pasta",
          "cereals",
          "breakfast",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-22-1.png",
        link: "https://amzn.to/3wlqULv",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "iron",
          "zinc",
          "vitamin",
          "vitals",
          "B12",
          "D",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-23.png",
        link: "https://amzn.to/3I5yTiw",
      },
      {
        keywords: [
          "baby",
          "wash",
          "soap",
          "shampoo",
          "cloths",
          "infant",
          "newborn",
          "cotton",
          "set",
          "100%",
          "pure",
          "himalaya",
          "mumma",
          "earth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-24-1-1-1-1.png",
        link: "https://amzn.to/3UOWOdM",
      },
    ],
  },
  {
    week: 24,
    slides: [
      {
        keywords: [
          "millet",
          "noodles",
          "pasta",
          "cereals",
          "breakfast",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-22-1.png",
        link: "https://amzn.to/3wlqULv",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "iron",
          "zinc",
          "vitamin",
          "vitals",
          "B12",
          "D",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-23.png",
        link: "https://amzn.to/3I5yTiw",
      },
      {
        keywords: [
          "baby",
          "wash",
          "soap",
          "shampoo",
          "cloths",
          "infant",
          "newborn",
          "cotton",
          "set",
          "100%",
          "pure",
          "himalaya",
          "mumma",
          "earth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-24-1-1-1-1.png",
        link: "https://amzn.to/3UOWOdM",
      },
    ],
  },
  {
    week: 25,
    slides: [
      {
        keywords: [
          "millet",
          "noodles",
          "pasta",
          "cereals",
          "breakfast",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-22-1.png",
        link: "https://amzn.to/3wlqULv",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "iron",
          "zinc",
          "vitamin",
          "vitals",
          "B12",
          "D",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-23.png",
        link: "https://amzn.to/3I5yTiw",
      },
      {
        keywords: [
          "baby",
          "wash",
          "soap",
          "shampoo",
          "cloths",
          "infant",
          "newborn",
          "cotton",
          "set",
          "100%",
          "pure",
          "himalaya",
          "mumma",
          "earth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-24-1-1-1-1.png",
        link: "https://amzn.to/3UOWOdM",
      },
    ],
  },
  {
    week: 26,
    slides: [
      {
        keywords: [
          "millet",
          "noodles",
          "pasta",
          "cereals",
          "breakfast",
          "snacks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-22-1.png",
        link: "https://amzn.to/3wlqULv",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "iron",
          "zinc",
          "vitamin",
          "vitals",
          "B12",
          "D",
          "tablets",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-23.png",
        link: "https://amzn.to/3I5yTiw",
      },
      {
        keywords: [
          "baby",
          "wash",
          "soap",
          "shampoo",
          "cloths",
          "infant",
          "newborn",
          "cotton",
          "set",
          "100%",
          "pure",
          "himalaya",
          "mumma",
          "earth",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-24-1-1-1-1.png",
        link: "https://amzn.to/3UOWOdM",
      },
    ],
  },
  {
    week: 27,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "bar",
          "soap",
          "handmade",
          "handcrafted",
          "organic",
          "natural",
          "sensitive",
          "skin",
          "sebamed",
          "hair",
          "care",
          "coconut",
          "olive",
          "neem",
          "teatree",
          "bhringraj",
          "oil",
          "onion",
          "adivasi",
          "herbal",
          "buddha",
          "Khadi",
          "amla",
          "pumpkin",
          "jojoba",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-29-min.png",
        link: "https://amzn.to/4bKvcwl",
      },
      {
        keywords: ["support", "feeding", "breast", "cotton", "cover"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-30-min.png",
        link: "https://amzn.to/3PgT3KD",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-2-1.png",
        link: "https://amzn.to/3uIE3xN",
      },
    ],
  },
  {
    week: 28,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-33-1.png",
        link: "https://amzn.to/49GKrEU",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-12-1-1.png",
        link: "https://amzn.to/49u6WNu",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-34-1-1-1-1-1-1-1-1-1.png",
        link: "https://amzn.to/3USc35y",
      },
    ],
  },
  {
    week: 29,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "bar",
          "soap",
          "handmade",
          "handcrafted",
          "organic",
          "natural",
          "sensitive",
          "skin",
          "sebamed",
          "hair",
          "care",
          "coconut",
          "olive",
          "neem",
          "teatree",
          "bhringraj",
          "oil",
          "onion",
          "adivasi",
          "herbal",
          "buddha",
          "Khadi",
          "amla",
          "pumpkin",
          "jojoba",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-29-min.png",
        link: "https://amzn.to/4bKvcwl",
      },
      {
        keywords: ["support", "feeding", "breast", "cotton", "cover"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-30-min.png",
        link: "https://amzn.to/3PgT3KD",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-2-1.png",
        link: "https://amzn.to/3uIE3xN",
      },
    ],
  },
  {
    week: 30,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-33-1.png",
        link: "https://amzn.to/49GKrEU",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-12-1-1.png",
        link: "https://amzn.to/49u6WNu",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-34-1-1-1-1-1-1-1-1-1.png",
        link: "https://amzn.to/3USc35y",
      },
    ],
  },
  {
    week: 31,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "bar",
          "soap",
          "handmade",
          "handcrafted",
          "organic",
          "natural",
          "sensitive",
          "skin",
          "sebamed",
          "hair",
          "care",
          "coconut",
          "olive",
          "neem",
          "teatree",
          "bhringraj",
          "oil",
          "onion",
          "adivasi",
          "herbal",
          "buddha",
          "Khadi",
          "amla",
          "pumpkin",
          "jojoba",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-29-min.png",
        link: "https://amzn.to/4bKvcwl",
      },
      {
        keywords: ["support", "feeding", "breast", "cotton", "cover"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-30-min.png",
        link: "https://amzn.to/3PgT3KD",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-2-1.png",
        link: "https://amzn.to/3uIE3xN",
      },
    ],
  },
  {
    week: 32,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-33-1.png",
        link: "https://amzn.to/49GKrEU",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-12-1-1.png",
        link: "https://amzn.to/49u6WNu",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-34-1-1-1-1-1-1-1-1-1.png",
        link: "https://amzn.to/3USc35y",
      },
    ],
  },
  {
    week: 33,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "bar",
          "soap",
          "handmade",
          "handcrafted",
          "organic",
          "natural",
          "sensitive",
          "skin",
          "sebamed",
          "hair",
          "care",
          "coconut",
          "olive",
          "neem",
          "teatree",
          "bhringraj",
          "oil",
          "onion",
          "adivasi",
          "herbal",
          "buddha",
          "Khadi",
          "amla",
          "pumpkin",
          "jojoba",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-29-min.png",
        link: "https://amzn.to/4bKvcwl",
      },
      {
        keywords: ["support", "feeding", "breast", "cotton", "cover"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-30-min.png",
        link: "https://amzn.to/3PgT3KD",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-2-1.png",
        link: "https://amzn.to/3uIE3xN",
      },
    ],
  },
  {
    week: 34,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-33-1.png",
        link: "https://amzn.to/49GKrEU",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-12-1-1.png",
        link: "https://amzn.to/49u6WNu",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-34-1-1-1-1-1-1-1-1-1.png",
        link: "https://amzn.to/3USc35y",
      },
    ],
  },
  {
    week: 35,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "bar",
          "soap",
          "handmade",
          "handcrafted",
          "organic",
          "natural",
          "sensitive",
          "skin",
          "sebamed",
          "hair",
          "care",
          "coconut",
          "olive",
          "neem",
          "teatree",
          "bhringraj",
          "oil",
          "onion",
          "adivasi",
          "herbal",
          "buddha",
          "Khadi",
          "amla",
          "pumpkin",
          "jojoba",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-29-min.png",
        link: "https://amzn.to/4bKvcwl",
      },
      {
        keywords: ["support", "feeding", "breast", "cotton", "cover"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-30-min.png",
        link: "https://amzn.to/3PgT3KD",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-2-1.png",
        link: "https://amzn.to/3uIE3xN",
      },
    ],
  },
  {
    week: 36,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-33-1.png",
        link: "https://amzn.to/49GKrEU",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-12-1-1.png",
        link: "https://amzn.to/49u6WNu",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-34-1-1-1-1-1-1-1-1-1.png",
        link: "https://amzn.to/3USc35y",
      },
    ],
  },
  {
    week: 37,
    slides: [
      {
        keywords: [
          "cleanser",
          "face",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "bar",
          "soap",
          "handmade",
          "handcrafted",
          "organic",
          "natural",
          "sensitive",
          "skin",
          "sebamed",
          "hair",
          "care",
          "coconut",
          "olive",
          "neem",
          "teatree",
          "bhringraj",
          "oil",
          "onion",
          "adivasi",
          "herbal",
          "buddha",
          "Khadi",
          "amla",
          "pumpkin",
          "jojoba",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-29-min.png",
        link: "https://amzn.to/4bKvcwl",
      },
      {
        keywords: ["support", "feeding", "breast", "cotton", "cover"],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-30-min.png",
        link: "https://amzn.to/3PgT3KD",
      },
      {
        keywords: [
          "face",
          "pigmentation",
          "acne",
          "treatment",
          "glowing",
          "cleanser",
          "wash",
          "cetaphil",
          "mamaearth",
          "tea",
          "tree",
          "uv",
          "sunscreen",
          "wow",
          "apple",
          "cider",
          "vinegar",
          "ubtan",
          "beauty",
          "care",
          "body",
          "lotion",
          "moisturizer",
          "essential",
          "stretch",
          "mark",
          "herbal",
          "cosmetics",
          "matte",
          "lipstick",
          "long",
          "lasting",
          "finish",
          "non-drying",
          "eyeshadow",
          "lip",
          "balm",
          "sponge",
          "blender",
          "makeup",
          "puff",
          "mamaearth",
          "multani",
          "mitti",
          "face",
          "ubtan",
          "roasted",
          "turmeric",
          "pack",
          "himalaya",
          "herbal",
          "natural",
          "de-tan",
          "neem",
          "tea",
          "tree",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/01/Last-two-Elements-916-2-1.png",
        link: "https://amzn.to/3uIE3xN",
      },
    ],
  },
  {
    week: 38,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-33-1.png",
        link: "https://amzn.to/49GKrEU",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-12-1-1.png",
        link: "https://amzn.to/49u6WNu",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-34-1-1-1-1-1-1-1-1-1.png",
        link: "https://amzn.to/3USc35y",
      },
    ],
  },
  {
    week: 39,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-33-1.png",
        link: "https://amzn.to/49GKrEU",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-12-1-1.png",
        link: "https://amzn.to/49u6WNu",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-34-1-1-1-1-1-1-1-1-1.png",
        link: "https://amzn.to/3USc35y",
      },
    ],
  },
  {
    week: 40,
    slides: [
      {
        keywords: [
          "skin",
          "care",
          "oil",
          "lotion",
          "moisturizer",
          "body",
          "essential",
          "stretch",
          "mark",
          "marks",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-33-1.png",
        link: "https://amzn.to/49GKrEU",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/02/Last-two-Elements-916-12-1-1.png",
        link: "https://amzn.to/49u6WNu",
      },
      {
        keywords: [
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "drink",
          "powder",
          "protein",
          "nutriefoods",
          "nutritious",
          "food",
          "snack",
          "roasted",
          "dry",
          "fruits",
          "dried",
          "fruits",
          "nuts",
          "Fruits",
          "herbal",
          "tea",
          "seeds",
          "nuts",
          "healthy",
          "food",
          "nutritious",
          "laddu",
          "cookies",
          "millet",
          "ragi",
        ],
        image:
          "https://tfh.cms.healthmachine.io/wp-content/uploads/sites/6/2024/03/Last-two-Elements-916-34-1-1-1-1-1-1-1-1-1.png",
        link: "https://amzn.to/3USc35y",
      },
    ],
  },
];
