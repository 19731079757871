import { Box, Divider, Flex, Grid, Image, Text } from "@chakra-ui/react";
import React from "react";
import { AiFillFacebook } from "react-icons/ai";
import { BsInstagram, BsYoutube } from "react-icons/bs";
import { FaTwitter } from "react-icons/fa";
import { RiQuestionnaireFill } from "react-icons/ri";
import Logo from "../../Asssets/logo2.png";

const Footer = () => {
  return (
    <Box
      className="footer"
      color={"gray"}
      marginTop={"20px"}
      fontFamily={"sans-serif"}
    >
      <Flex
        color={"black"}
        justifyContent="space-between"
        flexDir={{ lg: "row", md: "row", sm: "column", base: "column" }}
        mt="30px"
      >
        <Grid
          // border={"1px solid gray"}
          gridTemplateColumns={{
            base: "repeat(1,1fr)",
            sm: "repeat(2,1fr)",
            md: "repeat(2,1fr)",
            lg: "repeat(3,1fr)",
          }}
          gap={5}
        >
          <Flex
            gap={4}
            // border={"1px solid gray"}
            width={{ base: "220px", sm: "220px", md: "100%", lg: "100%" }}
            margin={{ sm: "auto", md: "0", lg: "0", base: "auto" }}
          >
            <Box
              border={"1px solid gray"}
              width="50px"
              height="50px"
              pt="6%"
              pl="6%"
              borderRadius={"50%"}
            >
              <RiQuestionnaireFill fontSize={"24px"} />
            </Box>
            <Box>
              <Text color={"gray"} fontSize={"14px"}>
                Help Centre
              </Text>
              <Text>info@togetherforher.com</Text>
            </Box>
          </Flex>
        </Grid>

        <Box
          // border={"1px solid gray"}
          alignItems="center"
          mt={"30px"}
        >
          <Flex
            alignItems="center"
            gap={10}
            margin="auto"
            justifyContent={"center"}
          >
            <Box>
              <a
                href={"https://www.facebook.com/togetherforherhealth"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillFacebook fontSize={"24px"} />
              </a>
            </Box>
            <Box>
              <a
                href={"https://twitter.com/TogetherForHer"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter fontSize={"24px"} />
              </a>
            </Box>
            <Box>
              <a
                href={"https://www.instagram.com/together_pregnancy_app/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsInstagram fontSize={"24px"} />
              </a>
            </Box>
            <Box>
              <a
                href={"https://www.youtube.com/@togetherforher"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsYoutube fontSize={"24px"} />
              </a>
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Divider mt="50px" />

      <Box>
        <Flex
          flexDir={{ lg: "row", md: "row", sm: "row", base: "column" }}
          gap={5}
          align={"center"}
          justify={"center"}
          mt="20px"
          pb={"20px"}
        >
          <Box>
            <Image
              alt="logo"
              width={{ base: "120px", sm: "120px", md: "170", lg: "170px" }}
              height={{ base: "40%", sm: "60%", md: "100%", lg: "100%" }}
              src={Logo}
            />
          </Box>
          {/* <Box>
            <Text
              color={"gray"}
              fontSize={{ base: "12px", sm: "14px", md: "14", lg: "14px" }}
            >
              © 2023 RNA Intellectual Property Limited.
            </Text>
            <Text
              color={"gray"}
              fontSize={{ base: "12px", sm: "14px", md: "14", lg: "14px" }}
            >
              Terms & Conditions - Privacy Policy
            </Text>
          </Box> */}
        </Flex>
      </Box>
    </Box>
  );
};

export default Footer;
