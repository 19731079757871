
import React, { useState } from 'react';
import './styles.css';

const HerHealth = () => {

  const [expandedStates, setExpandedStates] = useState(Array(3).fill(false));

  const toggleText = (index) => {
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const cardData = [
    {
      imageSrc: require('../../assets/Images/Pathology-tests-banner-V2.png'),
      description: 'Lab Tests, Ab Ghar Baithe Baithe! Convenient, home collection of samples. Exclusive discounts & the assurance of accredited labs for accurate results!',
      link: 'https://www.healthians.com/vendor-packages/avengehealthcare',
    },
    {
      imageSrc: require('../../assets/Images/Yoga-banner-v3.png'),
      description: 'Kifayti Pregnancy Yoga Sessions Build a strong foundation for your pregnancy with holistic well-being. Regular sessions led by certified instructors, live sessions with real-time guidance.',
      link: '/herhealth-yoga',
    },
    {
      imageSrc: require('../../assets/Images/emotional-support-banner.png'),
      description: "Ek khush haal aur shant mann aapki pregnancy ko bhi khush haal bana dega! Elevate your pregnancy journey with expert counselors specializing in mental health challenges. Affordable, personalized support—because you're not alone.",
      link: '/herhealth-emotional-support',
    },
  ];

  return (
    <div className="container">
      <div className="title">Complete care: Aapke aur aapke bache ke liye 🤰</div>
      <div className="bannerImageContainer">
        <img
          src={require('../../assets/Images/her-health-bannerV2.png')}
          alt="Banner"
          className="bannerImage"
        />
      </div>
      <div className="cardContainer">
        {cardData.map((card, index) => (
          <div key={index} className="card">
            <h2 className="cardHeader">{card.header}</h2>
            <a href={card.link}>
              <img
                src={card.imageSrc}
                alt={card.header}
                className="cardImage"
              />
            </a>
            <p className="cardDescription">
              {expandedStates[index] ? card.description : card.description.slice(0, 90) + '... '}
              <span className="readMoreLink" onClick={() => toggleText(index)}>
                {expandedStates[index] ? 'See less' : 'See more'}
              </span>
            </p>
          </div>
        ))}
      </div>
      {/* Footer */}
      <div className="footer">
        <div className="footerLogo">
          <img
            src={require('../../assets/Images/logo.png')}
            alt="TFH Logo"
            className="footerImage"
          />
        </div>
        <div className="termsAndConditions">
          <p>
            By using these services, you agree to our{' '}
            <a href="https://drive.google.com/file/d/1a2h197NwAjiAcrNaMGt-Mxp9EYKMAfAF/view">Terms and Conditions</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HerHealth;

