import React, { useState } from 'react';
import './styles.css';

const EmotionalSupport = () => {

    const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const cardClass = `how-it-works-card ${isExpanded ? 'expanded' : ''}`;
  return (
    <div className="emotional-support-page">
      <h1 className="page-title">🤗 Emotional Support 🤰</h1>
      <p className="subHeading">Ek Mentally Strong Maa Baniye! 👶</p>
      <img
          src={require('../../assets/Images/emotional-support.png')}
          alt="Yoga"
          className="headerImage"
        />

        <div className="how-it-works-card" style={{marginTop: 10, marginBottom: 10}}>
          <p className="about" style={{textAlign: "center"}}>
          1-on-1 Counseling: Aapke Mental Health Needs Ke Hisaab Se! 
          </p>
          <p className="about">
          Ek expectant woman ke liye pregnancy kaafi mentally challenging ho sakti hai.
          </p>
          <p className="about">Iss mansik tanav se bhari hui journey ko aap asaan bana sakte hain - by taking charge of your emotions.</p>
          <p className="about">Top 3 reasons to avail emotional support during pregnancy:</p>
          <p className="about">1. Ye aapke shareer aur mansik stress ko kam karega. </p>
          <p className="about">2. Aapki mental state ka aapke bache ko bohot zyada affect karti hai. </p>
          <p className="about">3. Ye ek sukhad pregnancy journey ko badhava dega. </p>
        </div>
      <div className={cardClass}>
      <h2 className="how-it-works-heading">HOW IT WORKS</h2>
      <div className="how-it-works-steps">
        <div className="how-it-works-step">
          <strong>Select Package:</strong> Pick a counseling package that suits your requirements, whether it's a single session or a subscription.
        </div>
        <div className="how-it-works-step">
          <strong>Submit Your Details:</strong> Share your counseling goals and provide basic information through a simple form.
          {!isExpanded && (
            <p className="see-more" onClick={toggleExpansion}>...See more
            </p>
          )}
        </div>
        {isExpanded ? (
          <>
            <div className="how-it-works-step">
              <strong>Make Payment:</strong> Securely pay using our convenient payment options.
            </div>
            <div className="how-it-works-step">
              <strong>The Counsellor Will Get in Touch:</strong> Once your payment is confirmed, a counselor from our panel will reach out to you.
            </div>
            <div className="how-it-works-step">
              <strong>Have a Session Arranged:</strong> Coordinate with a counselor to schedule a session at a mutually agreed-upon time.
              {isExpanded && (
              <p className="see-more" onClick={toggleExpansion}>
            ...See less
          </p>
        )}
            </div>
          </>
        ) : null}
        
      </div>
    </div>
      {/* Packages section */}
      <div className="packages-section">
        <PackageButton
          label="Trial Session"
          price="Rs. 1"
          url="https://pages.razorpay.com/pl_Mm17NekKNmt7nD/view"
        />
        <PackageButton
          label="Single Session"
          price="Rs. 499"
          url="https://pages.razorpay.com/pl_Mk0DxeIa4cDNS4/view"
        />
        <PackageButton
          label="Monthly Package (4 Sessions)"
          price="Rs. 1499"
          url="https://pages.razorpay.com/pl_Mk0NmmfyxW8hYL/view"
        />
        <PackageButton
          label="Quarterly Package (10 Sessions)"
          price="Rs. 2999"
          url="https://pages.razorpay.com/pl_Mk0RERTxUCann3/view"
        />
      </div>

      {/* Emotional support cards */}
      <h2 className="how-it-works-heading" style={{marginTop: 20}}>EXPERT COUNSELORS</h2>
      <div className="emotional-support-cards">
        <EmotionalSupportCard
          name="Ms./Mrs. Sushreeta Sule"
          yoe="6+ Years"
          qualification="BA, Psychology
          MA, Applied Psychology, University of Mumbai
          "
          expertise="Expertise in diverse problem areas: stress, anxiety, depression, relationship issues, trauma, etc."
          otherInfo="Active involvement with the State Mental Health Authority in Maharashtra."
        />
      </div>
      <div className="emotional-support-cards">
        <EmotionalSupportCard
          name="Ms./Mrs. Mahi Goyal"
          yoe="3 Years"
          qualification="Masters, Applied Psychology"
          expertise="Expertise across a range of problem areas: Mood and Anxiety Disorders, OCDs, self-esteem issues, relationship concerns, career-related problems, etc."
          otherInfo="Mahi is a counseling psychologist who believes that therapy is a collaborative process. She guides clients to increase self-awareness and personal strengths to have a more meaningful life experience."
        />
      </div>
      <div className="emotional-support-cards">
        <EmotionalSupportCard
          name="Ms./Mrs. Rachana Thaker"
          yoe="4+ Years"
          qualification="MA, Counselling Psychology"
          expertise="Experience in addressing psychological concerns ranging from stress, anxiety, and depression to relationship issues and self-esteem struggles. "
          otherInfo="Rachana is committed to guide individuals on their journey towards emotional well-being and personal growth."
        />
      </div>
      <div className="emotional-support-cards">
        <EmotionalSupportCard
          name="Dr. Geethanjali K R"
          yoe="5+ Years"
          qualification="MBBS
          MA, Psychology"
          expertise="Experienced Neurologist and Psychotherapist who has extensively counselled young adults through issues like Anxiety, Self-esteem issues, identity crisis, relationship issues, mood disorders."
          otherInfo="Accomplished neurologist and psychotherapist with expertise in young adult counseling, known for a thriving private practice and work at Samvad NGO."
        />
      </div>
      <div className="emotional-support-cards">
        <EmotionalSupportCard
          name="Ms./Mrs. Manjusha"
          yoe="10+ Years"
          qualification="MA, Counselling Psychology
          PG Diploma, Advance Counselling          
          "
          expertise="Expertise in dealing with a variety of problem areas like women’s issues, couples counselling, family and relationship counselling, geriatic counselling, etc."
          otherInfo=""
        />
      </div>

      <div className="footer">
        <div className="footerLogo">
          <img
            src={require('../../assets/Images/logo.png')}
            alt="TFH Logo"
            className="footerImage"
          />
        </div>
        </div>
      
    </div>
  );
};

const PackageButton = ({ label, price, url }) => {
  const handleBookNowClick = (e) => {
    e.stopPropagation();
    window.location.href = url;
  };

  return (
    <div className="package-button">
      <div>
        <p>{label}</p>
        <p className="package-price">{price}</p>
      </div>
      <button className="book-now-button" onClick={handleBookNowClick}><strong>Book Now</strong></button>
    </div>
  );
};

const EmotionalSupportCard = ({ name, yoe, qualification, expertise, otherInfo }) => {
  const [showExpertise, setShowExpertise] = useState(false);

  const toggleExpertise = () => {
    setShowExpertise(!showExpertise);
  };

  return (
    <div className="emotional-support-card">
      <div className="name-and-yoe">
        <p className="name">{name}</p>
        <p className="yoe">{yoe}</p>
      </div>
      <p className="qualification">{qualification}</p>
      <p className="expertise">
        {showExpertise ? expertise : `${expertise.substring(0, 40)}... `}
        <span className="see-more" onClick={toggleExpertise}>
          {showExpertise ? '' : 'See more'}
        </span>
      </p>
      {showExpertise && (
        <p className="other-info">
          {otherInfo}
          <span className="see-more" onClick={toggleExpertise}>
            See less
          </span>
        </p>
      )}
    </div>
  );
};

export default EmotionalSupport;
